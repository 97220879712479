

import request from '@/utils/request'
let baseUrl ='/carbon/carouselImage'

/**
 * @description: 轮播图图片
 * @return {*}
 */
export async function listPage(data) {
    return await request({
        url: baseUrl+'/listPage',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 添加轮播图图片
 * @return {*}
 */
export async function addpicture(data) {
    return await request({
        url: baseUrl+'/add',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 编辑轮播图图片
 * @return {*}
 */
export async function updatepicture(data) {
    return await request({
        url: baseUrl+'/update',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}


/**
 * @description: 批量删除轮播图图片
 * @return {*}
 */
export async function deletepicture(data) {
    return await request({
        url: baseUrl+'/deleteBatch',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}



