<template>
  <div id="user">
    <!-- 个人信息 -->
    <div class="outer">
      <div class="left">
        <div class="top">
          <div class="title">个人设置</div>
        </div>
        <div class="center">
          <div class="avatar">
            <el-upload
              :action="uploadData.action"
              :headers="uploadData.headers"
              :data="uploadData.data"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-error="handleAvatarError"
            >
              <div class="img">
                <img
                  v-if="userObj.titleImage"
                  :src="userObj.titleImage"
                  class="avatar"
                />
                <span v-else>上传头像</span>
              </div>
            </el-upload>
          </div>

          <div class="ipt">
            <div class="name">昵称</div>
            <div class="val">{{ userObj.nickName }}</div>
          </div>
          <div class="ipt">
            <div class="name">用户名</div>
            <div class="val">{{ userObj.userName }}</div>
          </div>
          <div class="ipt">
            <div class="name">手机号</div>
            <div class="val">{{ userObj.phone }}</div>
          </div>
          <div class="ipt">
            <div class="name">密码</div>
            <div class="val">{{ userObj.password }}</div>
          </div>
        </div>
        <div class="btom">
          <el-button
            type="primary"
            icon="el-icon-edit"
            @click="editrInfo"
            style="background-color: #415fff"
            >修改信息</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-edit"
            @click="editrPassword"
            style="background-color: #415fff"
            >修改密码</el-button
          >
        </div>
      </div>
    </div>

    <diy-drawer
      v-if="drawerData.flag"
      :drawerData="drawerData.data"
      :title="drawerData.title"
      :buttonList="drawerData.buttonList"
      @showDrawerData="showDrawerData"
      @colseDrawer="colseDrawer"
      @handleDrawerButton="handleDrawerButton"
    ></diy-drawer>
  </div>
</template>
<script>
import * as userData from "./user.js";
import * as user from "@/api/user/user.js";

export default {
  inject: ["reload"],
  data() {
    return {
      data: this.$base.initData("user"),
      multipleSelection: [],
      drawerData: {
        flag: false,
        width: "20%",
        title: "",
        data: [],
        buttonList: [],
      },
      staffListData: [], //员工下拉框数据
      id: "",
      userObj: {
        titleImage: "",
        userName: "admin",
        nickName: "管理员",
        phone: "13806789065",
        password: "admin",
      },
      uploadData: {
        action: process.env.VUE_APP_BASE_API + "/carbon/file/uploadFile",
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "用户头像",
          key: "file",
        },
      },
    };
  },
  methods: {
    async initTableData() {
      let reu = await user.getAdminInfo({});
      for (let key in reu) {
        if (this.userObj.hasOwnProperty(key)) {
          this.userObj[key] = reu[key];
        }
      }
    },
    //修改个人信息
    async editrInfo() {
      this.drawerData.title = "修改个人信息";
      this.drawerData.buttonList = [
        { name: "确定", key: "sureUserInfo" },
        { name: "取消", key: "cancel" },
      ];
      this.drawerData.data = userData.returnEditInfoData();
      this.drawerData.data = this.$total.json2array(
        this.drawerData.data,
        this.userObj
      );
      this.drawerData.flag = true;
    },
    //修改密码
    async editrPassword() {
      this.drawerData.title = "修改密码";
      this.drawerData.buttonList = [
        { name: "确定", key: "surePassword" },
        { name: "取消", key: "cancel" },
      ];
      this.drawerData.data = userData.returnEditPasswordData();
      this.drawerData.flag = true;
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    async handleAvatarSuccess(res, file) {
      this.userObj.titleImage = res.result.url;
      await user.saveUserInfo({ titleImage: this.userObj.titleImage });
    },
    handleAvatarError(err, file, fileList) {},

    /**
     * 抽屉按钮点击事件
     * @param {*} key
     */
    async handleDrawerButton(key) {
      let requestData = {};
      let flag;
      switch (key) {
        case "cancel":
          this.colseDrawer();
          break;
        case "sureUserInfo":
          // 确定修改个人信息
          flag = this.$total.judegArrayNull(this.drawerData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          let F;
          this.drawerData.data.forEach((item) => {
            if (item.key == "phone") {
              F = item.isTrue;
              return
            }
          });
          if (!F) {
            this.$message.error("手机号格式不正确");
            return;
          }
          requestData = this.$total.array2json(
            this.drawerData.data,
            requestData
          );
          requestData.titleImage=this.userObj.titleImage;
          await user.updateAdmin(requestData);
          this.$message({
            message: "个人信息修改成功!",
            type: "success",
          });
          localStorage.setItem("nickName", requestData.nickName);

          this.drawerData.flag = false;
          await this.initTableData();
          break;
        case "surePassword":
          // 确定修改密码
          flag = this.$total.judegArrayNull(this.drawerData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          
          requestData = this.$total.array2json(
            this.drawerData.data,
            requestData
          );
          await user.updatePasswordAdmin(requestData);
          this.$message({
            message: "密码修改成功!",
            type: "success",
          });
          this.drawerData.flag = false;
          await this.initTableData();
          break;

        default:
          break;
      }
    },
    /**
     * 添加或修改新闻资讯
     * @param {*} flag
     */
    async saveOrUpdateEmploy(flag) {},

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showTableData(val) {
      this.data.tableData = val;
    },
    showSearchData(val) {
      this.data.searchData = val;
    },
    showPageData(val) {
      this.data.pageData = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDrawer() {
      this.drawerData.flag = false;
    },
    showDrawerData(val) {
      this.drawerData.data = val;
    },
  },
  async mounted() {
    await this.initTableData();
  },
};
</script>
<style lang="less" scoped>
#user {
  width: 100%;
  height: 100%;
  display: flex;
  .outer {
    width: 100%;
    height: 100%;
    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding: 60px 100px;
      .top {
        display: flex;
        .title {
          font-size: 20px;
          color: #273848D9;
          margin-bottom: 50px;
        }
      }
      .center {
        display: flex;
        flex-direction: column;
        .avatar {
          width: 100%;
          .img {
            width: 100px;
            height: 100px;
            margin-left: 80px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 4px 6px rgba(50, 50, 93, .31), 0 1px 3px rgba(0, 0, 0, .08);
            > img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
            > span {
              display: block;
              width: 100%;
              height: 100%;
              background: #415fff;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
            }
          }
        }
        .ipt {
          display: flex;
          margin: 10px;
          min-height: 35px;
          line-height: 35px;
          margin: 5px;
          margin-left: 0;
          font-size: 16px;
          padding: 8px 0;
          .name {
            color: #475663CC;
            font-weight: 500;
            padding: 0 16px;
            text-indent: 0.7em;
            margin-right: 30px;
            white-space: nowrap;
            text-align: right;
            width: 130px;
          }
          .val {
            width: 50%;
            color: rgba(13, 27, 62, 0.65);
          }
        }
      }
      .btom {
        width: 100%;
        padding-left: 80px;
        margin-top: 100px;
      }
    }
  }
}
</style>
