<template>
  <div id="user-base">
    <!-- 新闻资讯 -->
    <diy-table
      :model="'newsBasic'"
      :tableData="data.tableData"
      :tableMenu="data.tableMenu"
      :searchData="data.searchData"
      :searchButton="data.searchButton"
      :pageData="data.pageData"
      @showTableData="showTableData"
      @showSearchData="showSearchData"
      @showPageData="showPageData"
      @handleSearch="handleSearch"
      @handleClick="handleClick"
      @handleSelectionChange="handleSelectionChange"
    ></diy-table>

    <diy-drawer
      v-if="drawerData.flag"
      :drawerData="drawerData.data"
      :title="drawerData.title"
      :buttonList="drawerData.buttonList"
      @handleSuccessImg="handleSuccessImg"
      @handleErrorImg="handleErrorImg"
      @beforeUploadImg="beforeUploadImg"
      @showDrawerData="showDrawerData"
      @colseDrawer="colseDrawer"
      @handleDrawerButton="handleDrawerButton"
      @openUeditor="openUeditor"
    ></diy-drawer>

    <ueditor-dialog
      v-if="ueditorData.flag"
      :ueditorData="ueditorData"
      @cancelDialog="cancelDialog"
      @makeSure="makeSureUeditor"
    >
    </ueditor-dialog>
  </div>
</template>
<script>
import * as newData from "./news.js";
import * as newsInformationData from "@/api/newsInformation/newsInformation.js";
import * as newsType from "@/api/newsType/newsType.js";
export default {
  inject: ["reload"],
  data() {
    return {
      data: this.$base.initData("newsBasic"),
      multipleSelection: [],

      dialogData: {
        tempData: {},
        flag: false,
        title: "",
        width: "",
        data: [],
        buttonList: [],
      },
      drawerData: {
        flag: false,
        width: "20%",
        title: "",
        data: [],
        buttonList: [],
      },
      tempKey: "",
      ueditorData: {
        flag: false,
        width: "60%",
        title: "",
        content: "",
      },
      newsTypeListData: [], //新闻类型
    };
  },
  methods: {
    async initTableData() {
      let requestData = {
        pageSize: this.data.pageData.pageSize,
        pageNum: this.data.pageData.currentPage,
      };
      requestData = this.$total.array2json(this.data.searchData, requestData);
      let newsListData = await newsInformationData.newsList(requestData);
      this.data.tableData = newsListData.records;
      this.data.pageData.total = newsListData.total;
    },
    /**
     * 顶部搜索按钮点击事件
     * @param {*} key
     */
    async handleSearch(key) {
      switch (key) {
        case "search":
          // 查询
          await this.initTableData();
          break;
        case "add":
          // 新增
          this.drawerData.title = "添加新闻资讯";
          this.drawerData.buttonList = [
            { name: "确定", key: "sureAddEmploy" },
            { name: "取消", key: "cancel" },
          ];
          this.drawerData.data = newData.returnEditData();
          this.$total.fillArrayOptions(
            this.drawerData.data,
            "typeId",
            this.newsTypeListData
          );
          this.drawerData.flag = true;
          break;
        case "edit":
          // 编辑
          if (
            this.multipleSelection.length == 0 ||
            this.multipleSelection.length > 1
          ) {
            this.$message({
              message: "请选择一条数据进行编辑!",
              type: "error",
            });
            return;
          }
          this.drawerData.title = "编辑新闻资讯";
          this.drawerData.buttonList = [
            { name: "确定", key: "sureUpdateEmploy" },
            { name: "取消", key: "cancel" },
          ];

          this.drawerData.data = newData.returnEditData();
          this.drawerData.data = this.$total.json2array(
            this.drawerData.data,
            this.multipleSelection[0]
          );
          this.drawerData.data.forEach((element) => {
            if (element.key == "picUrl") {
              element.imgList.push(element.value);
            }
          });
          this.$total.fillArrayOptions(
            this.drawerData.data,
            "typeId",
            this.newsTypeListData
          );
          this.drawerData.flag = true;
          break;
        case "delete":
          // 删除
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请至少选择一条数据进行删除操作!",
              type: "error",
            });
            return;
          }

          this.$confirm("此操作将永久删除所选的信息, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              let ids = "";
              this.multipleSelection.forEach((item) => {
                ids = item.id + "," + ids;
              });
              let requestData = { ids: ids };
              await newsInformationData.delNews(requestData);
              await this.initTableData();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;

        default:
          break;
      }
    },

    /**
     * 表格按钮点击事件
     * @param {*} val
     */
    handleClick(val) {
      let row = val.row;
      let key = val.key;
      switch (key) {
        case "content":
          //新闻内容
          this.ueditorData.width = "60%";
          this.ueditorData.title = "查看新闻内容";
          this.ueditorData.content = row.content;
          this.ueditorData.flag = true;
          break;

        default:
          break;
      }
    },
    /**
     * 抽屉按钮点击事件
     * @param {*} key
     */
    async handleDrawerButton(key) {
      let requestData = {};
      let flag;
      switch (key) {
        case "cancel":
          this.colseDrawer();
          break;
        case "sureAddEmploy":
          // 添加新闻资讯
          flag = this.$total.judegArrayNull(this.drawerData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          requestData = this.$total.array2json(
            this.drawerData.data,
            requestData
          );
          await newsInformationData.addNews(requestData);
          this.$message({
            message: "新闻资讯添加成功!",
            type: "success",
          });
          this.drawerData.flag = false;
          await this.initTableData();
          break;
        case "sureUpdateEmploy":
          // 编辑新闻资讯
          flag = this.$total.judegArrayNull(this.drawerData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          requestData = this.$total.array2json(
            this.drawerData.data,
            requestData
          );
          requestData.id = this.multipleSelection[0].id;
          await newsInformationData.editNews(requestData);
          this.$message({
            message: "新闻资讯修改成功!",
            type: "success",
          });
          this.drawerData.flag = false;
          await this.initTableData();
          break;
        default:
          break;
      }
    },

    /**
     * 打开富文本编辑器
     * @param {*} item
     */
    openUeditor(item) {
      // console.log(item);
      // setTimeout(() => {
      this.tempKey = item.key;
      this.ueditorData.title = "编辑" + item.title;
      this.ueditorData.content = item.value;
      this.ueditorData.flag = true;
      // }, 500);
    },
    //确定编辑
    makeSureUeditor(val) {
      this.drawerData.data.forEach((element) => {
        if (element.key == this.tempKey) {
          element.value = val.contentHtml;
          return;
        }
      });
      this.cancelDialog();
    },
    /**
     * 添加或修改新闻资讯
     * @param {*} flag
     */
    async saveOrUpdateEmploy(flag) {},

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showTableData(val) {
      this.data.tableData = val;
    },
    showSearchData(val) {
      this.data.searchData = val;
    },
    showPageData(val) {
      this.data.pageData = val;
    },
    cancelDialog() {
      this.ueditorData.flag = false;
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDrawer() {
      this.drawerData.flag = false;
    },
    showDrawerData(val) {
      this.drawerData.data = val;
    },
    handleSuccessImg(response, file, fileList) {
      if (response.code == "200") {
        switch (response.result.title) {
          case "新闻封面":
            // 新闻封面
            this.drawerData.data.forEach((element) => {
              if (element.key == "picUrl") {
                let url = response.result.url;
                element.imgList = [url];
                element.value = url;
              }
            });
            break;

          default:
            break;
        }
      }
      this.loading = false;
    },
    handleErrorImg() {
      this.$message({
        message: "文件上传失败!",
        type: "warning",
      });
      this.loading = false;
    },
    beforeUploadImg(file) {
      // console.log(file);
      // if (
      //   file.type == "image/jpeg" ||
      //   file.type == "image/jpg" ||
      //   file.type == "image/png"
      // ) {
      //   this.loading = true;
      // } else {
      //   this.$message({
      //     message: "仅支持上传JPG/JPEG/PNG格式!",
      //     type: "warning",
      //   });
      //   return false;
      // }
    },
  },
  async mounted() {
    //获取新闻分类
    let requestData = {
      pageSize: this.data.pageData.pageSize,
      pageNum: this.data.pageData.currentPage,
    };
    let data = await newsType.listPageNewsTypem(requestData);
    this.newsTypeListData = this.$total.convert(data.records);
    this.$total.fillArrayOptions(
      this.data.searchData,
      "typeId",
      this.newsTypeListData
    );
   
    await this.initTableData();
  },
};
</script>
<style lang="less" scoped>
#user-base {
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
