import request from "@/utils/request";
let baseUrl = "/business/auth";

/**
 * @description: 获取角色列表
 * @return {*}
 */
export async function roleList(data) {
  return await request({
    url: baseUrl + "/authList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 添加角色
 * @return {*}
 */
export async function addroleList(data) {
  return await request({
    url: baseUrl + "/addAuth",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 编辑角色
 * @return {*}
 */
export async function editroleList(data) {
  return await request({
    url: baseUrl + "/editAuth",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除角色
 * @return {*}
 */
export async function delroleList(data) {
  return await request({
    url: baseUrl + "/delAuth",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 复制角色
 * @return {*}
 */
export async function copyroleList(data) {
  return await request({
    url: baseUrl + "/copyRole",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 复制人员
 * @return {*}
 */
export async function copyUserList(data) {
  return await request({
    url: baseUrl + "/copyroleList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 保存人员权限
 * @return {*}
 */
export async function setUserAuth(data) {
  return await request({
    url: baseUrl + "/setUserAuth",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 给角色设置权限
 * @return {*}
 */
export async function setAuthForRole(data) {
  return await request({
    url: baseUrl + "/setAuthForRole",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取权限树
 * @return {*}
 */
export async function getBaseAuthTree(data) {
  return await request({
    url: baseUrl + "/getBaseAuthTree",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取菜单
 * @return {*}
 */
export async function getAuthorityPath(data) {
  return await request({
    url: baseUrl + "/allMenu",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取菜单
 * @return {*}
 */
export async function getRoleAuthTree(data) {
  return await request({
    url: baseUrl + "/getRoleAuthTree",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取权限按钮
 * @return {*}
 */
export async function getAuthority(data) {
  return await request({
    url: "/business/auth/getAuthority",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}
