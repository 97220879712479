/*
 * @Author: yang
 * @Date: 2024-07-16 10:28:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-14 19:38:05
 * @Description: 环保项目模块接口
 */


import request from '@/utils/request'
let baseUrl ='/carbon/greenProject'

/**
 * @description: 获取环保项目模块列表
 * @return {*}
 */
export async function greenProjectList(data) {
    return await request({
        url: baseUrl+'/listPage',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 添加环保项目
 * @return {*}
 */
export async function addgreenProject(data) {
    return await request({
        url: baseUrl+'/add',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 编辑环保项目
 * @return {*}
 */
export async function editgreenProject(data) {
    return await request({
        url: baseUrl+'/update',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 批量删除环保项目
 * @return {*}
 */
export async function delgreenProject(data) {
    return await request({
        url: baseUrl+'/deleteBatch',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}




