/*
 * @Author: yang
 * @Date: 2024-07-15 09:07:52
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-13 17:32:50
 * @Description: 请填写简介
 */

let url = "/carbon/file/uploadFile"; //上传地址
/**
 * 模拟接口数据
 */
export function initData() {
  return [];
}

/**
 * 编辑的界面数据
 * @returns
 */
export function returnEditData() {
  return [
    {
      title: "物品排序",
      key: "sort",
      value: "",
      type: "input-number",
      isNotNull: true,
    },
    {
      title: "物品分类",
      key: "typeId",
      value: "",
      type: "select",
      options: [],
      isNotNull: true,
    },
    {
      title: "物品名称",
      key: "name",
      value: "",
      type: "input",
      isNotNull: true,
    },
    {
      title: "物品图片",
      key: "picUrl",
      value: "",
      imgList:[],
      type: "upload-img",
      isNotNull: true,
      uploadData: {
        action: process.env.VUE_APP_BASE_API + url,
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "物品封面",
        },
      },
    },
    {
      title: "中文单位",
      key: "unitZh",
      value: "",
      type: "input",
      isNotNull: true,
    },
    {
      title: "英文单位",
      key: "unitEn",
      value: "",
      type: "input",
      isNotNull: true,
    },
    {
      title: "每单位排碳量",
      key: "carbonEmissions",
      value: "",
      type: "input",
      isNotNull: true,
    },
  ];
}

/**
 * 弹框的基本数据
 * @returns
 */

export function returnDialogData() {
  return [
    {
      title: "薪资模版下载",
      key: "model",
      value: "",
      type: "downloadBtn",
      isNotNull: false,
    },
    {
      title: "导入基本信息",
      key: "filePath",
      value: "",
      type: "upload",
      isNotNull: false,
      fileList: [],
      uploadData: {
        action: process.env.VUE_APP_BASE_API + url,
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "薪资信息",
          key: "filePath",
        },
      },
    },
  ];
}
