/*
 * @Author: yang
 * @Date: 2024-08-22 13:35:03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-23 09:08:30
 * @Description: 文件注释:
 */


/**
 * 修改个人信息
 * @returns
 */
export function returnEditInfoData() {
  return [
    {
      title: "手机号",
      key: "phone",
      value: "",
      type: "phone",
      isNotNull: true,
      isTrue:true,
    },
    {
      title: "昵称",
      key: "nickName",
      value: "",
      type: "input",
      isNotNull: true,
    },
    {
      title: "用户名",
      key: "userName",
      value: "",
      type: "input-en-num",
      isNotNull: true,
    },
   
  ];
}


/**
 * 修改密码
 * @returns
 */
export function returnEditPasswordData() {
  return [
    {
      title: "原密码",
      key: "password",
      value: "",
      type: "psw",
      isNotNull: true,
    },
    {
      title: "新密码",
      key: "newPassword",
      value: "",
      type: "psw",
      isNotNull: true,
    },
    {
      title: "再次确认密码",
      key: "confirmPassword",
      value: "",
      type: "psw",
      isNotNull: true,
    },
    
  ];
}
