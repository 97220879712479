/*
 * @Author: yang
 * @Date: 2024-07-11 15:40:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-23 10:30:16
 * @Description: 请填写简介
 */

/**
 * 模拟接口数据
 */
export function initData() {
  return [];
}

/**
 * 编辑的界面数据
 * @returns
 */
export function returnEditData() {
  return [
    {
      title: "单位名称",
      key: "companyName",
      value: "",
      type: "select",
      options: [],
      isNotNull: true,
    },
  ];
}

/**
 * 弹框的基本数据
 * @returns
 */
let url = "/uploadFile"; //导入路径
export function returnDialogData() {
  return [
    {
      title: "请假模版下载",
      key: "model",
      value: "",
      type: "downloadBtn",
      isNotNull: false,
    },
    {
      title: "导入基本信息",
      key: "filePath",
      value: "",
      type: "upload",
      isNotNull: false,
      fileList: [],
      uploadData: {
        action: process.env.VUE_APP_BASE_API + url,
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "请假登记信息",
          key: "filePath",
        },
      },
    },
  ];
}
