/*
 * @Author: yang
 * @Date: 2024-08-12 17:14:28
 * @LastEditors: 
 * @LastEditTime: 2024-08-23 15:31:08
 * @Description: 文件注释:
 */
/**
 * 模拟接口数据
 */
export function initData() {
  return [];
}

let url = "/carbon/file/uploadFile"; //上传地址

/**
 * 编辑的界面数据
 * @returns
 */
export function returnEditData() {
  return [
    {
      title: "新闻排序",
      key: "sort",
      value: "",
      type: "input-number",
      isNotNull: true,
    },
    {
      title: "新闻类型",
      key: "typeId",
      value: "",
      type: "select",
      isNotNull: true,
      options: [],
    },
    {
      title: "新闻标题",
      key: "title",
      value: "",
      type: "input",
      isNotNull: true,
    },
    {
      title: "封面",
      key: "picUrl",
      value: "",
      imgList:[],
      type: "upload-img",
      isNotNull: true,
      uploadData: {
        action: process.env.VUE_APP_BASE_API + url,
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "新闻封面",
        },
      },
    },
    {
      title: "新闻内容",
      key: "content",
      value: "",
      type: "richtext",
      isNotNull: true,
    },
    {
      title: "是否展示",
      key: "isIndex",
      value: 1,
      type: "radio",
      isNotNull: true,
      options: [
        { label: "展示", value: 1 },
        { label: "不展示", value: 0 },
      ],
    },
    {
      title: "发布时间",
      key: "publishTime",
      value: "",
      type: "date1",
      isNotNull: true,
    },
  ];
}


