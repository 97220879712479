/**
 * 跳转的tag标签
 * @param {*} name 标签名称
 * @param {*} query 标签携带的参数
 * @param {*} path 跳转链接
 * @param {*} activeMenu 左侧相应菜单
 */
export function returnTag(name, query, path, activeMenu) {
  let tag = {
    path: path,
    fullPath: path,
    query: query,
    name: name,
    meta: {
      title: name,
      activeMenu: activeMenu,
    },
  };
  return tag;
}

/**
 * 初始化各界面结构
 * @param {*} model
 * @returns
 */
export function initData(model) {
  let data = {};
  switch (model) {
    case "newsBasic":
      // 新闻资讯
      data = {
        tableMenu: [
          { title: "排序", key: "sort", width: "120", type: "text" },
          { title: "封面", key: "picUrl", width: "120", type: "show-img" },
          { title: "新闻标题", key: "title", width: "", type: "text" },
          {
            title: "新闻类型",
            key: "typeName",
            width: "200",
            type: "text",
          },
          {
            title: "新闻内容",
            key: "content",
            width: "",
            type: "link",
            showText: "详情",
            linkPath: "newsContent",
          },
          { title: "发布时间", key: "publishTime", width: "200", type: "text" },
          {
            title: "访问量",
            key: "visitCount",
            width: "100",
            type: "text",
          },
          {
            title: "是否展示",
            key: "isIndex",
            width: "100",
            type: "text",
            isChangeText: true,
            isChangeColor: true,
          },
        ],
        tableButton: [],
        tableData: [],
        searchData: [
          {
            title: "查询新闻标题",
            type: "input",
            value: "",
            key: "title",
            clearable: true,
          },
          {
            title: "发布时间",
            type: "date1",
            value: "",
            key: "publishTime",
            clearable: true,
          },
          {
            title: "新闻类别",
            type: "select",
            value: "",
            key: "typeId",
            clearable: true,
            options: [
              { label: "科技类", value: "y" },
              { label: "实用类", value: "n" },
            ],
          },
        ],
        searchButton: initAllButton(model),
        pageData: {
          currentPage: 1,
          total: 30,
          pageSizes: [10, 20, 50, 100],
          pageSize: 10,
        },
      };
      break;
    case "carbonTools":
      // 碳转换装备
      data = {
        tableMenu: [
          { title: "排序", key: "sort", width: "120", type: "text" },
          { title: "设备图片", key: "picUrl", width: "120", type: "show-img" },
          { title: "设备名称", key: "title", width: "", type: "text" },
          {
            title: "设备内容",
            key: "content",
            width: "",
            type: "link",
            showText: "详情",
            linkPath: "newsContent",
          },
          // { title: "发布时间", key: "publishTime", width: "200", type: "text" },
          // {
          //   title: "访问量",
          //   key: "visitCount",
          //   width: "100",
          //   type: "text",
          // },
          {
            title: "是否展示",
            key: "isIndex",
            width: "100",
            type: "text",
            isChangeText: true,
            isChangeColor: true,
          },
        ],
        tableButton: [],
        tableData: [],
        searchData: [
          {
            title: "查询设备名称",
            type: "input",
            value: "",
            key: "title",
            clearable: true,
          },
        ],
        searchButton: initAllButton(model),
        pageData: {
          currentPage: 1,
          total: 30,
          pageSizes: [15, 20, 50, 100],
          pageSize: 15,
        },
      };
      break;
    case "goodsInfo":
      // 物品信息
      data = {
        tableMenu: [
          { title: "物品分类", key: "typeName", width: "", type: "text" },
          { title: "物品名称", key: "name", width: "", type: "text" },
          { title: "物品图片", key: "picUrl", width: "", type: "show-img" },
          { title: "中文单位", key: "unitZh", width: "", type: "text" },
          { title: "英文单位", key: "unitEn", width: "", type: "text" },
          {
            title: "每单位排碳量",
            key: "carbonEmissions",
            width: "",
            type: "text",
          },
          { title: "排序", key: "sort", width: "", type: "text" },
        ],
        tableButton: [],
        tableData: [],
        searchData: [
          {
            title: "物品名称",
            type: "input",
            value: "",
            key: "name",
            clearable: true,
          },
          {
            title: "物品分类",
            type: "select",
            value: "",
            key: "typeId",
            clearable: true,
            options: [],
          },
        ],
        searchButton: initAllButton(model),
        pageData: {
          currentPage: 1,
          total: 30,
          pageSizes: [15, 20, 50, 100],
          pageSize: 15,
        },
      };
      break;
    case "greenProject":
      // 环保项目模块
      data = {
        tableMenu: [
          { title: "环保名称", key: "name", width: "", type: "text" },
          { title: "单位", key: "unit", width: "", type: "text" },
          {
            title: "每单位节约排碳量",
            key: "carbonEmissions",
            width: "",
            type: "text",
          },
          {
            title: "排序",
            key: "sort",
            width: "",
            type: "text",
          },
        ],
        tableButton: [],
        tableData: [],
        searchData: [
          {
            title: "环保名称",
            type: "input",
            value: "",
            key: "name",
            clearable: true,
          },
        ],
        searchButton: initAllButton(model),
        pageData: {
          currentPage: 1,
          total: 30,
          pageSizes: [15, 20, 50, 100],
          pageSize: 15,
        },
      };
      break;
    case "newsType":
      // 新闻类型
      data = {
        tableMenu: [
          { title: "排序", key: "sort", width: "", type: "text" },
          { title: "新闻类型名称", key: "typeName", width: "", type: "text" },
        ],
        tableButton: [],
        tableData: [],
        searchData: [
          {
            title: "新闻类型名称",
            type: "input",
            value: "",
            key: "typeName",
            clearable: true,
          },
        ],
        searchButton: initAllButton(model),
        pageData: {
          currentPage: 1,
          total: 30,
          pageSizes: [15, 20, 50, 100],
          pageSize: 15,
        },
      };
      break;
    case "league":
      // 合作与加盟
      data = {
        tableMenu: [
          {
            title: "单位名称",
            key: "companyName",
            width: "",
            type: "text",
          },
          { title: "联系人", key: "contactsName", width: "", type: "text" },
          {
            title: "联系电话",
            key: "contactsPhone",
            width: "",
            type: "text",
          },
          {
            title: "留言",
            key: "message",
            width: "",
            type: "text",
          },
        ],
        tableButton: [],
        tableData: [],
        searchData: [
          {
            title: "单位名称",
            type: "input",
            value: "",
            key: "companyName",
            clearable: true,
          },
          // {
          //   title: "联系人",
          //   type: "input",
          //   value: "",
          //   key: "contactsName",
          //   clearable: true,
          // },
        ],
        searchButton: initAllButton(model),
        pageData: {
          currentPage: 1,
          total: 30,
          pageSizes: [15, 20, 50, 100],
          pageSize: 15,
        },
      };
      break;
    case "goodsItem":
      // 物品分类
      data = {
        tableMenu: [
          { title: "排序", key: "sort", width: "", type: "text" },
          {
            title: "物品类别名称",
            key: "typeName",
            width: "",
            type: "text",
          },
        ],
        tableButton: [],
        tableData: [],
        searchData: [
          {
            title: "查询类别名称",
            type: "input",
            value: "",
            key: "typeName",
            clearable: true,
          },
        ],
        searchButton: initAllButton(model),
        pageData: {
          currentPage: 1,
          total: 30,
          pageSizes: [15, 20, 50, 100],
          pageSize: 15,
        },
      };
      break;
    case "picture":
      // 轮播图
      data = {
        tableMenu: [
          {
            title: "轮播图分类",
            key: "picType",
            width: "",
            type: "text",
            isChangeText: true,
            isChangeColor: false,
          },
          { title: "轮播图图片", key: "picUrl", width: "", type: "show-img" },
          { title: "排序", key: "sort", width: "", type: "text" },
          {
            title: "是否展示",
            key: "isIndex",
            width: "",
            type: "text",
            isChangeText: true,
            isChangeColor: true,
          },
        ],
        tableButton: [],
        tableData: [],
        searchData: [
          {
            title: "轮播图分类",
            type: "select",
            value: null,
            key: "picType",
            clearable: true,
            options: [
              {
                label: "首页轮播图",
                value: 0,
              },
              {
                label: "工具箱轮播图",
                value: 1,
              },
            ],
          },
          {
            title: "是否展示",
            type: "select",
            value: null,
            key: "isIndex",
            clearable: true,
            options: [
              {
                label: "不展示",
                value: 0,
              },
              {
                label: "展示",
                value: 1,
              },
            ],
          },
        ],
        searchButton: initAllButton(model),
        pageData: {
          currentPage: 1,
          total: 30,
          pageSizes: [15, 20, 50, 100],
          pageSize: 15,
        },
      };
      break;

    default:
      break;
  }
  return data;
}

/**
 * 初始化所有界面的按钮
 * @param {*} model
 * @returns
 */
export function initAllButton(model) {
  let list = [];
  switch (model) {
    case "newsBasic":
      list = [
        { icon: "el-icon-plus", title: "添加", key: "add" },
        { icon: "el-icon-edit-outline", title: "编辑", key: "edit" },
        { icon: "el-icon-delete", title: "删除", key: "delete" },
      ];
      break;
    case "carbonTools":
      list = [
        { icon: "el-icon-plus", title: "新增", key: "add" },
        { icon: "el-icon-edit-outline", title: "编辑", key: "edit" },
        { icon: "el-icon-delete", title: "删除", key: "delete" },
      ];
      break;
    case "goodsInfo":
      list = [
        { icon: "el-icon-plus", title: "新增", key: "add" },
        { icon: "el-icon-edit-outline", title: "编辑", key: "edit" },
        { icon: "el-icon-delete", title: "删除", key: "delete" },
        // { icon: "el-icon-download", title: "导入", key: "import" },
        // { icon: "el-icon-upload2", title: "导出", key: "export" },
      ];
      break;
    case "greenProject":
      list = [
        { icon: "el-icon-plus", title: "新增", key: "add" },
        { icon: "el-icon-edit-outline", title: "编辑", key: "edit" },
        { icon: "el-icon-delete", title: "删除", key: "delete" },
        // { icon: "el-icon-download", title: "导入", key: "import" },
        // { icon: "el-icon-upload2", title: "导出", key: "export" },
      ];
      break;
    case "newsType":
      list = [
        { icon: "el-icon-plus", title: "新增", key: "add" },
        { icon: "el-icon-edit-outline", title: "编辑", key: "edit" },
        { icon: "el-icon-delete", title: "删除", key: "delete" },
        // { icon: "el-icon-download", title: "导入", key: "import" },
        // { icon: "el-icon-upload2", title: "导出", key: "export" },
      ];
      break;
    case "league":
      list = [
        // { icon: "el-icon-plus", title: "新增", key: "add" },
        // { icon: "el-icon-edit-outline", title: "编辑", key: "edit" },
        { icon: "el-icon-delete", title: "删除", key: "delete" },
        // { icon: "el-icon-download", title: "导入", key: "import" },
        // { icon: "el-icon-upload2", title: "导出", key: "export" },
      ];
      break;
    case "goodsItem":
      list = [
        { icon: "el-icon-plus", title: "新增", key: "add" },
        { icon: "el-icon-edit-outline", title: "编辑", key: "edit" },
        { icon: "el-icon-delete", title: "删除", key: "delete" },
        // { icon: "el-icon-download", title: "导入", key: "import" },
        // { icon: "el-icon-upload2", title: "导出", key: "export" },
      ];
      break;
    case "picture":
      list = [
        { icon: "el-icon-plus", title: "新增", key: "add" },
        { icon: "el-icon-edit-outline", title: "编辑", key: "edit" },
        { icon: "el-icon-delete", title: "删除", key: "delete" },
        // { icon: "el-icon-download", title: "导入", key: "import" },
        // { icon: "el-icon-upload2", title: "导出", key: "export" },
      ];
      break;

    default:
      break;
  }
  return list;
}

/**
 * 根据状态显示所有按钮
 * @param {*} model 模块
 * @param {*} status
 * @returns
 */
export function initAllButtonByStatus(model, status) {
  let list = [];
  switch (model) {
    case "value":
      break;

    default:
      break;
  }
  return list;
}

/**
 * 根据状态码显示文字
 * @param {*} model 模块
 * @param {*} code
 * @returns
 */
export function getStatusNameByNum(model, code) {
  let str = "";
  switch (model) {
    case "newsBasicisIndex":
      // 新闻资讯-是否展示
      switch (code) {
        case 0:
          str = "不展示";
          break;
        case 1:
          str = "展示";
          break;

        default:
          break;
      }
      break;

    case "color_newsBasicisIndex":
      // 新闻资讯--门卡使用情况
      switch (code) {
        case 0:
          str = "#f56c6c";
          break;
        case 1:
          str = "#5cBB7A";
          break;

        default:
          break;
      }
      break;
    case "carbonToolsisIndex":
      // 碳转换设备-是否展示
      switch (code) {
        case 0:
          str = "不展示";
          break;
        case 1:
          str = "展示";
          break;

        default:
          break;
      }
      break;

    case "color_carbonToolsisIndex":
      // 碳转换设备--颜色
      switch (code) {
        case 0:
          str = "#f56c6c";
          break;
        case 1:
          str = "#5cBB7A";
          break;

        default:
          break;
      }
      break;

    case "newsBasicgreenProjectStatus":
      // 新闻资讯--环保项目状态
      switch (code) {
        case "y":
          str = "在职";
          break;
        case "n":
          str = "已环保项目";
          break;
        case "w":
          str = "交接中";
          break;

        default:
          break;
      }
      break;
    case "color_newsBasicgreenProjectStatus":
      // 新闻资讯--环保项目状态
      switch (code) {
        case "y":
          str = "#67C23A";
          break;
        case "n":
          str = "#F56C6C";
          break;
        case "w":
          str = "#41a9e9";
          break;

        default:
          break;
      }
      break;
    case "goodsInfopayStatus":
      // 物品信息--工资发放状态
      switch (code) {
        case "y":
          str = "已发放";
          break;
        case "n":
          str = "未发放";
          break;

        default:
          break;
      }
      break;
    case "color_goodsInfopayStatus":
      // 物品信息--工资发放状态
      switch (code) {
        case "y":
          str = "#67C23A";
          break;
        case "n":
          str = "#F56C6C";
          break;

        default:
          break;
      }
      break;
    case "greenProjectisBlacklist":
      // 环保项目模块--是否黑名单
      switch (code) {
        case "y":
          str = "是";
          break;
        case "w":
          str = "否";
          break;

        default:
          break;
      }
      break;
    case "greenProjectisGrant":
      // 环保项目模块--工资是否发放
      switch (code) {
        case "y":
          str = "已发放";
          break;
        case "w":
          str = "未发放";
          break;

        default:
          break;
      }
      break;
    case "color_greenProjectisGrant":
      // 环保项目模块--工资是否发放颜色
      switch (code) {
        case "y":
          str = "#67C23A";
          break;
        case "w":
          str = "#F56C6C";
          break;

        default:
          break;
      }
      break;
    case "greenProjectgreenProjectStatus":
      // 环保项目模块--环保项目状态
      switch (code) {
        case "y":
          str = "已环保项目";
          break;
        case "w":
          str = "交接中";
          break;

        default:
          break;
      }
      break;
    case "color_greenProjectgreenProjectStatus":
      // 环保项目模块--环保项目状态文字颜色
      switch (code) {
        case "y":
          str = "#F56C6C";
          break;
        case "w":
          str = "#41a9e9";
          break;

        default:
          break;
      }
      break;
    case "newsTypenewsTypeStatus":
      // 新闻类型--合同状态
      switch (code) {
        case "y":
          str = "生效中";
          break;
        case "w":
          str = "已失效";
          break;

        default:
          break;
      }
      break;
    case "color_newsTypenewsTypeStatus":
      // 新闻类型--合同状态
      switch (code) {
        case "y":
          str = "#67C23A";
          break;
        case "w":
          str = "#F56C6C";
          break;

        default:
          break;
      }
      break;
      case "pictureisIndex":
        // 轮播图-是否展示
        switch (code) {
          case 0:
            str = "不展示";
            break;
          case 1:
            str = "展示";
            break;
  
          default:
            break;
        }
        break;
  
      case "color_pictureisIndex":
        // 轮播图--颜色
        switch (code) {
          case 0:
            str = "#f56c6c";
            break;
          case 1:
            str = "#5cBB7A";
            break;
  
          default:
            break;
        }
        break;
        case "picturepicType":
          // 轮播图-类型
          switch (code) {
            case 0:
              str = "首页轮播图";
              break;
            case 1:
              str = "工具箱轮播图";
              break;
    
            default:
              break;
          }
          break;

    default:
      break;
  }
  return str;
}
