<template>
  <div id="auth">
    <!-- 权限管理 -->
    <div class="left">
      <div class="tabs">
        <div
            :class="{ tab: true, 'is-active-tab': activeName == tab.key }"
            @click="handleClick(tab.key)"
            v-for="tab in tabList"
        >
          {{ tab.name }}
        </div>
      </div>
      <div class="l-s">
        <el-input
            v-model="name"
            clearable
            placeholder="请输入内容"
            @keyup.enter.native="getList"
        ></el-input>
      </div>
      <div class="l-list customScrollBar">
        <div
            :class="{ item: true, 'is-avtive': tempData.value == item.value }"
            v-for="item in visibleData"
            :key="item.value"
            @click="chooseAuth(item, activeName)"
        >
          <span>{{ item.label }}</span>
          <el-dropdown>
            <i class="iconfont icon-gengduo"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="but in leftButton" :key="but.name">
                <div @click="handleButClick(but.key, item)">{{ but.name }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="addbox" v-if="visibleData.length == 0">
        <div class="addrole" @click="handleBtnAddRole">
          <span>添加角色</span>
        </div>
      </div>
      <!-- 分页 -->
      <div class="l-pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout=" prev, pager, next"
            :total="total"
            small
            :pager-count="5"
            background
            :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </div>
    <div class="right">
      <div class="r-t">
        <div class="but" @click="savePermission(activeName)">
          <i class="el-icon-check"></i>保存
        </div>
        <div class="but" @click="canclePermission">
          <i class="el-icon-close"></i>取消
        </div>
      </div>
      <div class="r-c customScrollBar">
        <div class="item" v-for="item in data" :key="item.key">
          <el-checkbox
              v-model="item.auth"
              @change="handleCheckAllChange(item)"
          >{{ item.name }}
          </el-checkbox
          >
          <div class="child-item">
            <!-- <el-checkbox-group
              v-model="item.checkedAuth"
              @change="handleCheckedAuthChange(item)"
            >
              <el-checkbox
                v-for="butAuth in item.children"
                :label="butAuth"
                :key="butAuth.key"
                :checked="butAuth.auth"
                v-model="butAuth.auth"
                @change="handleCheckedAuthItemChange(butAuth)"
                >{{ butAuth.name }}</el-checkbox
              >
            </el-checkbox-group> -->
            <el-checkbox
                v-for="butAuth in item.children"
                :label="butAuth"
                :key="butAuth.key"
                :checked="butAuth.auth"
                v-model="butAuth.auth"
                @change="handleCheckedAuthItemChange(item, butAuth)"
            >{{ butAuth.name }}
            </el-checkbox
            >
          </div>
        </div>
      </div>
    </div>
    <diy-dialog
        v-if="dialogData.flag"
        :title="dialogData.title"
        :width="dialogData.width"
        :maxHeight="dialogData.maxHeight"
        :dialogData="dialogData.data"
        :buttonList="dialogData.buttonList"
        @showDialogData="showDialogData"
        @colseDialog="colseDialog"
        @dialogOper="dialogOper"
    ></diy-dialog>
  </div>
</template>
<script>
import * as AuthData from "./auth.js";
import * as auth from "@/api/auth/auth.js";

import * as CommonData from "@/api/common/common.js";

export default {
  inject: ["reload"],
  data() {
    return {
      tabList: [
        {name: "人员列表", key: "employee"},
        {name: "角色列表", key: "role"},
      ],
      activeName: "employee",
      name: "",
      list: [
        {id: "1", name: "张三"},
        {id: "1-1", name: "张三"},
        {id: "2", name: "张三"},
        {id: "3", name: "张三"},
      ], // 存储所有数据的数组
      visibleData: [], // 存储当前可见的数据的数组
      leftButton: [],
      tempData: {id: "1", name: "张三"},
      data: [],
      currentPage: 1,
      total: 3000,
      pageSizes: [10, 20, 50, 100],
      pageSize: 50,
      dialogData: {
        tempData: {},
        flag: false,
        title: "",
        width: "",
        data: [],
        buttonList: [],
        maxHeight: "300px",
      },
      userList: [],
      allData: [], // 存储所有从后端获取的数据
      page: 1, // 当前页码
      perPage: 100, // 每页显示的数据条数
      isLoading: false, // 是否正在加载数据
      hasMoreData: true, // 是否还有更多数据可以加载
      checkedAuth: [],
      auth: [], //权限id组
    };
  },
  methods: {
    /**
     * 获取人员和角色列表
     * @param {*} key
     */
    getList() {
      switch (this.activeName) {
        case "employee":
          //人员列表 (根据当前页和分页处理数据)
          this.getAllUserList();
          break;
        case "role":
          //角色列表 (根据当前页和分页处理数据)
          this.getRoleList();
          break;
        default:
          break;
      }
    },
    /**
     * tab点击事件
     * @param {*} key
     */
    handleClick(key) {
      this.currentPage = 1;
      this.activeName = key;
      this.leftButton = AuthData.getLeftButton(key);
      this.getList();
    },
    /**
     * 获取左侧列表
     */
    async getList() {
      switch (this.activeName) {
        case "employee":
          //人员列表 (根据当前页和分页处理数据)
          await this.getAllUserList();
          await this.chooseAuth(this.visibleData[0], this.activeName);
          break;

        case "role":
          //角色列表 (根据当前页和分页处理数据)
          await this.getRoleList();
          //没有角色时
          if (this.visibleData.length == 0) {
            // this.visibleData.push({ label: "测试角色", value: "" });
            return;
          }
          await this.chooseAuth(this.visibleData[0], this.activeName);
          break;

        default:
          break;
      }
      // await this.chooseAuth(this.visibleData[0], this.activeName);
    },
    /**
     * 左侧数据的按钮事件
     * @param {*} key
     */
    async handleButClick(key, item) {
      await this.chooseAuth(item, key);

      switch (key) {
        case "copyUser":
          // 复制当前用户权限给其他用户
          this.dialogData.flag = true;
          this.dialogData.width = "30%";
          this.dialogData.title = "人员权限复制";
          this.dialogData.buttonList = [
            {name: "确定", key: "sureCopyUser"},
            {name: "取消", key: "cancel"},
          ];
          this.dialogData.data = AuthData.userDialogData();
          this.$total.fillArrayOptions(
              this.dialogData.data,
              "tagIds",
              this.list
          );
          break;
        case "copyRole":
          // 复制当前角色权限给其他角色
          this.dialogData.flag = true;
          this.dialogData.width = "30%";
          this.dialogData.title = "角色权限复制";
          this.dialogData.buttonList = [
            {name: "确定", key: "sureCopyRole"},
            {name: "取消", key: "cancel"},
          ];
          this.dialogData.data = AuthData.copyDialogData();
          this.$total.fillArrayOptions(
              this.dialogData.data,
              "tagIds",
              this.list
          );
          break;
        case "editRole":
          // 编辑角色
          this.dialogData.flag = true;
          this.dialogData.width = "30%";
          this.dialogData.title = "角色名称编辑";
          this.dialogData.buttonList = [
            {name: "确定", key: "sureEditRole"},
            {name: "取消", key: "cancel"},
          ];
          this.dialogData.data = AuthData.addDialogData();
          this.dialogData.data[0].value = this.tempData.label;
          break;
        case "deleteRole":
          // 删除角色
          this.$confirm("此操作将永久删除所选的信息, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
              .then(async () => {
                await auth.delroleList({id: this.tempData.value});
                this.getRoleList(); //
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          break;
        case "addRole":
          // 添加角色

          this.dialogData.flag = true;
          this.dialogData.width = "30%";
          this.dialogData.title = "角色名称添加";
          this.dialogData.buttonList = [
            {name: "确定", key: "sureAddRole"},
            {name: "取消", key: "cancel"},
          ];
          this.dialogData.data = AuthData.addDialogData();
          break;

        default:
          break;
      }
    },
    //添加角色
    handleBtnAddRole() {
      this.dialogData.flag = true;
      this.dialogData.width = "30%";
      this.dialogData.title = "角色名称添加";
      this.dialogData.buttonList = [
        {name: "确定", key: "sureAddRole"},
        {name: "取消", key: "cancel"},
      ];
      this.dialogData.data = AuthData.addDialogData();
    },
    /**
     * 获取权限
     * @param {*} item
     */
    async chooseAuth(item, key) {
      this.tempData = item;
      let requestData = {
        id: item.value,
      };
      switch (key) {
        case "employee":
          //人员
          let authTree = await auth.getBaseAuthTree(requestData);
          authTree.forEach((element) => {
            element.checkedAuth = [];
          });
          this.data = authTree;
          break;

        case "role":
          //角色
          let authRoleTree = await auth.getRoleAuthTree(requestData);
          authRoleTree.forEach((element) => {
            element.checkedAuth = [];
          });
          this.data = authRoleTree;

        default:
          break;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.updateVisibleData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.updateVisibleData();
    },

    //获取人员列表
    async getAllUserList() {
      this.list = this.$total.changeKeyForArray(
          await CommonData.getAllUserList({
            name: this.name,
          })
      );
      let listData = await CommonData.getAllUserListByPage({
        name: this.name,
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      });
      this.visibleData = this.$total.changeKeyForArray(listData.tableData);
      this.total = listData.total;
      // this.updateVisibleData();
    },
    updateVisibleData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.visibleData = this.list.slice(startIndex, endIndex);
    },
    //获取角色
    async getRoleList() {
      let requestData = {
        name: this.name,
      };
      this.visibleData = this.$total.changeKeyForArray(
          await auth.roleList(requestData)
      );
      this.total = this.visibleData.length - 1;
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 弹出框的操作事件
     * @param {*} key
     */
    async dialogOper(key) {
      let requestData = {};
      let flag;
      switch (key) {
        case "sureCopyUser":
          //人员复制
          flag = this.$total.judegArrayNull(this.dialogData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          requestData = this.$total.array2json(
              this.dialogData.data,
              requestData
          );
          requestData.id = this.tempData.value;
          await auth.copyUserList(requestData);
          this.$message({
            message: "人员权限复制成功!",
            type: "success",
          });
          this.colseDialog();

          break;
        case "sureCopyRole":
          //角色复制
          flag = this.$total.judegArrayNull(this.dialogData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          requestData = this.$total.array2json(
              this.dialogData.data,
              requestData
          );
          requestData.id = this.tempData.value;
          await auth.copyroleList(requestData);
          this.$message({
            message: "角色权限复制成功!",
            type: "success",
          });
          this.colseDialog();

          break;
        case "sureEditRole":
          //编辑角色

          flag = this.$total.judegArrayNull(this.dialogData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          requestData = this.$total.array2json(
              this.dialogData.data,
              requestData
          );
          requestData.id = this.tempData.value;
          await auth.editroleList(requestData);
          this.$message({
            message: "角色编辑成功!",
            type: "success",
          });
          this.getRoleList();
          this.colseDialog();

          break;
        case "sureAddRole":
          //添加角色
          flag = this.$total.judegArrayNull(this.dialogData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          requestData = this.$total.array2json(
              this.dialogData.data,
              requestData
          );
          await auth.addroleList(requestData);
          this.$message({
            message: "角色添加成功!",
            type: "success",
          });
          this.getRoleList();

          this.colseDialog();

          break;

        case "cancel":
          this.colseDialog();

          break;

        default:
          break;
      }
    },
    //保存权限
    async savePermission(key) {
      let requestData = {};
      let ids = [];
      switch (this.activeName) {
        case "employee":
          ids = AuthData.collectUniqueAuthIds(this.data);
          requestData = {
            name: this.tempData.label,
            id: this.tempData.value,
            auth: ids,
          };
          await auth.setUserAuth(requestData);
          this.$message({
            showClose: true,
            message: "保存成功!",
            type: "success",
          });
          break;
        case "role":
          ids = AuthData.collectUniqueAuthIds(this.data);
          requestData = {
            name: this.tempData.label,
            id: this.tempData.value,
            auth: ids,
          };
          await auth.setAuthForRole(requestData);
          this.$message({
            showClose: true,
            message: "保存成功!",
            type: "success",
          });
          break;
        default:
          break;
      }
    },
    //取消权限
    canclePermission() {
      this.$message({
        showClose: true,
        message: "已取消",
      });
    },

    handleCheckAllChange(item) {
      item.checkedAuth = [];
      if (!item.auth) {
        item.children.forEach((element) => {
          element.auth = false;
        });
      }
    },
    handleCheckedAuthChange(item) {
      item.auth = true;
    },
    handleCheckedAuthItemChange(item, butAuth) {
      item.auth = true;
      butAuth.auth = true;
    },
  },
  async mounted() {
    //获取角色列表
    await this.getRoleList();
    //获取人员列表
    await this.getAllUserList();
    //人员
    this.handleClick("employee");
  },
};
</script>
<style lang="less" scoped>
#auth {
  width: 100%;
  height: 100%;
  display: flex;

  > .left {
    width: 17%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #d3d8db;
    border-top-right-radius: 4px;
    overflow-x: hidden;
    position: relative;

    > .tabs {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px;
      box-sizing: border-box;

      > .tab {
        width: 0;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #33333300;
        padding: 10px 0;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 14px;
        transition: 0.3s;
      }

      > .is-active-tab {
        color: #415fff;
        border-color: #415fff;
      }
    }

    > .l-s {
      width: 100%;
      height: 40px;
      padding: 8px;
      box-sizing: border-box;
      margin-bottom: 5px;
    }

    > .l-list {
      width: 100%;
      height: 0;
      flex: 1;
      padding: 8px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;

      > .item {
        width: 100%;
        height: 35px;
        min-height: 35px;
        font-size: 14px;
        margin: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;
        cursor: pointer;
        transition: 0.3s;
      }

      > .is-avtive {
        color: #ffffff;
        background-color: #415fff;
        border-radius: 4px;

        /deep/ i {
          color: #fff;
        }
      }
    }

    > .addbox {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 35px;
      min-height: 35px;
      font-size: 14px;
      margin: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      cursor: pointer;
      transition: 0.3s;

      > .addrole {
        width: 100%;
        height: 100%;
        background-color: #415fff;
        border-radius: 4px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    > .l-pagination {
      margin: 10px 0;
    }
  }

  > .right {
    width: 0;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    box-sizing: border-box;

    > .r-t {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
    }

    > .r-c {
      width: 70%;
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      margin-top: 10px;
      padding: 30px;
      box-sizing: border-box;
      background-color: #c7dfff42;

      > .item {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 28px;

        > .child-item {
          // width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-left: 50px;
          box-sizing: border-box;
          margin-top: 13px;
        }

        /deep/ .el-checkbox {
          width: 80px;
        }

        /deep/ .el-checkbox__inner {
          width: 20px;
          height: 20px;
          border-radius: 10px;
        }

        /deep/ .el-checkbox__inner::after {
          top: 4px;
          left: 7px;
        }

        /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #333;
        }

        /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
        /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #0256ff;
          border-color: #0256ff;
        }
      }
    }
  }

  .but {
    width: auto;
    height: 30px;
    background-color: #415fff;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 8px;

    > i {
      margin-right: 5px;
    }
  }
}
</style>
