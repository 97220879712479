let url = "/carbon/file/uploadFile"; //上传地址
/**
 * 模拟接口数据
 */
export function initData() {
  return [];
}

/**
 * 编辑的界面数据
 * @returns
 */
export function returnEditData() {
  return [
    {
      title: "排序",
      key: "sort",
      value: "",
      type: "input-number",
      isNotNull: true,
    },
    {
      title: "轮播图分类",
      key: "picType",
      value: null,
      type: "select",
      options: [
        { label: "首页轮播图", value: 0 },
        { label: "工具箱轮播图", value: 1 },
      ],
      isNotNull: true,
    },
    {
      title: "轮播图图片",
      key: "picUrl",
      value: "",
      imgList: [],
      type: "upload-img",
      isNotNull: true,
      uploadData: {
        action: process.env.VUE_APP_BASE_API + url,
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "物品封面",
        },
      },
    },
    {
      title: "是否展示",
      key: "isIndex",
      value: 1,
      type: "radio",
      isNotNull: true,
      options: [
        { label: "展示", value: 1 },
        { label: "不展示", value: 0 },
      ],
    },
  ];
}
