/*
 * @Author: yang
 * @Date: 2024-07-23 16:55:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-23 17:59:05
 * @Description: 请填写简介
 */
let url = "/carbon/file/uploadFile"; //导入路径
export function initData() {
  return [
    
  ];
}

/**
 * 编辑的界面数据
 * @returns
 */
export function returnEditData() {
  return [
    {
      title: "排序",
      key: "sort",
      value: "",
      type: "input-number",
      isNotNull: true,
    },
   
    {
      title: "设备名称",
      key: "title",
      value: "",
      type: "input",
      isNotNull: true,
    },
    {
      title: "设备图片",
      key: "picUrl",
      value: "",
      imgList:[],
      type: "upload-img",
      isNotNull: true,
      uploadData: {
        action: process.env.VUE_APP_BASE_API + url,
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "设备图片",
        },
      },
    },
    {
      title: "设备内容",
      key: "content",
      value: "",
      type: "richtext",
      isNotNull: true,
    },
    {
      title: "是否展示",
      key: "isIndex",
      value: 1,
      type: "radio",
      isNotNull: true,
      options: [
        { label: "展示", value: 1 },
        { label: "不展示", value: 0 },
      ],
    },
    
  ];
}

