import request from '@/utils/request'

// 获取验证码
export async function getCodeImg() {
    return await request({
        url: '/captchaImage',
        headers: {
            isToken: false
        },
        method: 'get',
        timeout: 20000
    })
}

export async function login(data) {
    return await request({
        url: '/carbon/user/loginAdmin',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}