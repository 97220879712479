<template>
  <div id="diy-dialog">
    <div class="content" @click.stop="clickNull" :style="{ width: width, 'max-height': maxHeight }">
      <div class="colse" @click="colseDialog">
        <i class="el-icon-close"></i>
      </div>
      <div class="title">{{ title }}</div>

      <div class="dialog-content">
        <div class="dialog-content-line" v-for="(item, index) in data" :key="index">
          <div class="dialog-content-line-title" style="
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              padding-top: 15px;
            " v-if="item.title">
            <div class="xx" v-if="item.isNotNull">*</div>
            {{ item.title }}:
          </div>

          <div class="dialog-content-line-value" v-if="item.type == 'input'">
            <el-input v-model="item.value" :placeholder="'请输入' + item.title" clearable></el-input>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'input_psw'">
            <el-input v-model="item.value" show-password></el-input>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'text'">
            {{ item.value }}
          </div>
          <div class="dialog-content-line-value height_auto" v-if="item.type == 'text_left'">
            {{ item.value }}
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'radio'">
            <el-radio-group v-model="item.value" @change="changeFlag" :disabled="item.flag">
              <el-radio :label="it.value" v-for="(it, ii) in item.options" :key="ii">{{
      it.label
    }}</el-radio>
            </el-radio-group>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'input_carriage'">
            <el-input v-model="item.value" :placeholder="item.placeholder" @keyup.enter.native="enter"></el-input>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'textarea'">
            <el-input type="textarea" show-word-limit :rows="4" :maxlength="1000" v-model="item.value"
              :placeholder="'请输入' + item.title"></el-input>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'select'">
            <el-select v-model="item.value" filterable :placeholder="'请选择' + item.title" :disabled="item.flag">
              <el-option v-for="it in item.options" :key="it.value" :label="it.label" :value="it.value">
              </el-option>
            </el-select>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'select-html'">
            <el-select v-model="item.value" filterable :placeholder="item.placeholder" :disabled="item.flag">
              <el-option v-for="it in item.options" :key="it.value" :label="it.label" :value="it.value">
                <span v-html="it.label"></span>
              </el-option>
            </el-select>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'select-obj'">
            <el-select v-model="item.value" :placeholder="item.placeholder" filterable value-key="value">
              <el-option v-for="it in item.options" :key="it.value" :label="it.label" :value="it">
              </el-option>
            </el-select>
          </div>

          <div class="dialog-content-line-value" v-if="item.type == 'selects'">
            <el-select v-model="item.value" :placeholder="item.placeholder" filterable multiple>
              <el-option v-for="it in item.options" :key="it.value" :label="it.label" :value="it.value">
              </el-option>
            </el-select>
          </div>
          <!--  -->
          <div class="dialog-content-line-value" v-if="item.type == 'select-object'">
            <el-select v-model="item.value" :placeholder="item.placeholder" multiple filterable value-key="value">
              <el-option v-for="it in item.options" :key="it.value" :label="it.label" :value="it">
              </el-option>
            </el-select>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'select-object-collapse'">
            <el-select v-model="item.value" :placeholder="item.placeholder" multiple filterable collapse-tags
              value-key="value">
              <el-option v-for="it in item.options" :key="it.value" :label="it.label" :value="it">
              </el-option>
            </el-select>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'select-search'">
            <el-select v-model="item.value" :placeholder="item.placeholder" filterable>
              <el-option v-for="it in item.options" :key="it.value" :label="it.label" :value="it.value">
              </el-option>
            </el-select>
          </div>
          <div class="dialog-content-line-value div-flex-wrap" v-if="item.type == 'select-bk'">
            <!-- 边框选择界面 -->
            <div :class="{
      'value-item': true,
      'value-item-avtive': item.value == it.value,
    }" v-for="(it, ii) in item.options" :key="ii" @click="changeValue(item, it)">
              <i :class="it.icon"></i>
              {{ it.label }}
            </div>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'time'">
            <el-date-picker v-model="item.value" type="datetime" placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'time_date'">
            <el-date-picker v-model="item.value" type="date" placeholder="选择日期时间" value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
          <!-- 日期选择今天后面的时候不能选 -->
          <div class="dialog-content-line-value" v-if="item.type == 'date1'">
            <el-date-picker clearable v-model="item.value" type="date" :placeholder="'请选择' + item.title"
              format="yyyy 年 MM 月 dd 日 " value-format="yyyy-MM-dd" :picker-options="pickerOptions"
              popper-class="popperClass">
            </el-date-picker>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'ymdt_time'">
            <el-date-picker v-model="item.value" type="datetimerange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'times'">
            <el-date-picker v-model="item.value" type="datetimerange" :picker-options="item.pickerOptions"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'add-tip-select-search'">
            <el-select v-model="item.value" :placeholder="item.placeholder" filterable
              @change="changeHandle(item.value, index)" value-key="value">
              <el-option v-for="it in item.options" :key="it.value" :label="it.label" :value="it">
              </el-option>
            </el-select>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'tips'">
            <el-tag v-for="tag in item.value" :key="tag.value" closable @close="handleClose(tag, index, 0)">
              {{ tag.label }}
            </el-tag>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'tips_text'">
            <el-tag v-for="tag in item.value" :key="tag" closable @close="handleClose(tag, index, 1)">
              {{ tag }}
            </el-tag>
            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
              @keyup.enter.native="handleInputConfirm(index)" @blur="handleInputConfirm(index)">
            </el-input>
            <el-button type="success" class="button-new-tag" @click="showInput" size="mini">+</el-button>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'tips_select'">
            <el-tag v-for="tag in item.value" :key="tag" closable @close="handleClose(tag, index)">
              {{ tag }}
            </el-tag>
            <el-select class="input-new-tag" v-if="inputVisible" filterable v-model="inputValue" ref="saveTagInput"
              size="small" @change="handleInputConfirm(index)">
              <el-option v-for="itt in item.options" :key="itt.value" :label="itt.label" :value="itt.value">
              </el-option>
            </el-select>
            <el-button type="success" class="button-new-tag" @click="showInput" size="mini">+</el-button>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'add-but'">
            <el-button type="success" @click="dialogOper(item.active)" size="mini">{{
      item.text
    }}</el-button>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'button'">
            <div v-if="item.value.length > 0">
              <el-tag v-for="tag in item.value" :key="tag.id">{{ tag.text }}</el-tag>
            </div>
            <el-button type="success" @click="dialogOper(item.active)" size="mini">{{
      item.text
    }}</el-button>
          </div>
          <div class="dialog-content-line-value div-flex-wrap" v-if="item.type == 'icons'"
            v-viewer="{ movable: false }">
            <template v-for="icon in item.value">
              <img v-if="icon != ''" :src="icon" class="dialog-content-line-value-img" alt="" object-fit="scale-down" />
              <div v-else style="width: 180px;height:240px; border: 1px solid #bdbdbd;box-sizing: border-box;"></div>
            </template>

          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'table'">
            <!-- 可选择的表格 -->
            <el-table :data="item.value" border style="width: 100%" height="600">
              <el-table-column type="index" label="序号" width="55"> </el-table-column>
              <el-table-column :prop="menu.key" :label="menu.name" v-for="(menu, ii) in item.menu" :key="ii">
              </el-table-column>
            </el-table>
          </div>
          <div class="dialog-content-line-value" v-if="item.type == 'upload'">
            <!-- 上传文件 -->
            <el-upload class="upload-demo" :action="item.uploadData.action" :headers="item.uploadData.headers"
              :show-file-list="true" :multiple="false" :file-list="item.fileList" :data="item.uploadData.data"
              :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess" :on-error="handleError">
              <el-button size="small" type="primary">选择文件</el-button>
              <div class="el-upload__tip" slot="tip">
                只能上传xlsx,xls文件，且不超过500kb
              </div>
            </el-upload>
          </div>
          <div class="dialog-content-line-value" style="flex-direction: column; align-items: flex-start"
            v-if="item.type == 'downloadBtn'">
            <!-- 模版下载 -->
            <el-button size="small" type="primary" @click="downloadModel">模板文件下载</el-button>
            <div class="el-upload__tip" slot="tip" style="color: #f56c6c">
              导入前,请先将模版文件下载,填写数据后,再进行导入操作
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-buts">
        <div class="dialog-but" v-for="(item, index) in buttonList" :key="index" @click="dialogOper(item.key)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "diy_dialog",
  props: {
    dialogData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "40%",
    },
    maxHeight: {
      type: String,
      default: "400px",
    },

    buttonList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: this.dialogData,
      inputVisible: false,
      inputValue: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  watch: {
    dialogData() {
      this.data = this.dialogData;
    },
    data(newValue) {
      this.$emit("showDialogData", this.data);
    },
  },
  methods: {
    colseDialog() {
      this.$emit("colseDialog");
      // this.dialogOper("cancel");
    },
    clickNull() { },
    changeFlag() { },
    dialogOper(active) {
      this.$emit("dialogOper", active);
    },
    showAddUser(value) {
      this.data.forEach((element) => {
        if (element.type == "add-user") {
          element.value = value;
        }
      });
    },

    showInput() {
      this.inputVisible = true;
    },
    handleInputConfirm(index) {

      let inputValue = this.inputValue;
      if (inputValue) {
        this.data[index].value.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleClose(tag, index) {
      this.data[index].value.splice(this.data[index].value.indexOf(tag), 1);
    },
    changeValue(item, data) {
      item.value = data.value;
    },
    showPaper(value) {
      this.$emit("showPaper", value);
    },
    handleClickChangePaper(id) {
      this.$emit("handleClickChangePaper", id);
    },
    handleAvatarSuccess(response, file, fileList) {
      this.$emit("handleAvatarSuccess", response, file, fileList);
    },
    handleError(err, file, fileList) {

      this.$emit("handleError");
    },
    beforeAvatarUpload(file) {
      let flag;
      this.$emit("beforeAvatarUpload", file, (res) => {
        flag = res;
      });
      return flag;
    },
    downloadModel() {
      this.$emit("downloadModel");
    },
  },
  mounted() { },
};
</script>

<style lang="less" scoped>
#diy-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000036;
  z-index: 99;
  display: flex;
  justify-content: center;

  >.content {
    width: 65%;
    height: auto;
    max-height: 400px;
    background-color: #fff;
    margin-top: 100px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    >.colse {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }

    >.title {
      width: 100%;
      padding-left: 20px;
      border-left: 5px double #333;
      box-sizing: border-box;
      display: flex;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .dialog-content {
      width: 100%;
      height: 0;
      flex: 1;
      min-height: 50px;
      max-height: 900px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      &::-webkit-scrollbar {
        width: 1px;
      }

      .dialog-content-line {
        display: flex;
        width: 95%;
        height: auto;
        // min-height: 30px;
        // max-height: 300px;
        // overflow-y: auto;
        margin: 5px;

        &::-webkit-scrollbar {
          width: 1px;
        }

        .dialog-content-line-title {
          width: 25%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          margin-right: 15px;
          min-height: 30px;

          >.xx {
            color: #f00;
            font-size: 20px;
            // margin-top: 4px;
            margin-right: 8px;
          }
        }

        .dialog-content-line-value {
          flex: 1;
          height: 100%;
          width: 0;
          max-height: 900px;
          display: flex;
          align-items: center;

          .dialog-content-line-value-active {
            flex: 1;
          }

          .dialog-content-line-value-img {
            margin: 2px;
            width: 180px;
            min-width: 180px;
          }

          >.value-item {
            width: 120px;
            height: 120px;
            background-color: #f7f7f7bd;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            margin: 3px;
            flex-direction: column;
            border: 1px solid #cecece;
            border-radius: 3px;
            cursor: pointer;

            i {
              margin-bottom: 4px;
            }

            transition: 0.3s;

            &:hover {
              border-color: #9ada8b;
              background-color: #9ada8b7a;
              color: #37a81d;

            }
          }

          >.value-item-avtive {
            border-color: #9ada8b;
            background-color: #9ada8b7a;
            color: #37a81d;
          }

          /deep/ .el-select {
            width: 100%;
          }

          /deep/ .el-date-editor.el-input,
          .el-date-editor.el-input__inner {
            width: 100%;
          }

          /deep/ .el-picker-panel {
            width: 488px;
          }

          /deep/ .el-date-picker {
            width: 488px;
          }
        }
      }
    }

    .dialog-buts {
      width: 100%;
      height: 50px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .dialog-but {
        width: auto;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
        padding: 0 15px;
        box-sizing: border-box;
        margin: 0 5px;
        background: #415fff;
      }

      .dialog-but:hover {
        cursor: pointer;
      }
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .flex-column {
      flex-direction: column;
    }

    .button-new-tag {
      margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }

    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }

    .height_auto {
      height: auto !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      font-size: 13px;
      line-height: 28px;
      color: rgb(0, 140, 255);
    }

    .tip_tip {
      margin-left: 5px;
    }

    .el-cascader {
      width: 100%;
    }

    .el-tag {
      margin: 0 5px;
    }
  }

  /deep/.el-upload-list__item {
    transition: none;
  }

  /deep/ .el-list-enter-active,
  /deep/ .el-list-leave-active {
    transition: none;
  }

  /deep/ .el-list-enter,
  /deep/ .el-list-leave-active {
    opacity: 0;
  }

  /deep/ .el-upload-list {
    height: 20px;
  }

  .popperClass {
    width: 488px;
  }
}
</style>
