import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
import test from "../views/test/test.vue";

import Login from "../views/login/login.vue";
import Main from "../views/main.vue";

import newsInfo from "../views/newsInformation/index.vue";

import carbonTools from "../views/carbonTools/index.vue";

import goodsInfo from "../views/goodsInfo/index.vue";
import picture from "../views/picture/index.vue";

import greenProject from "../views/greenProject/index.vue";

import ContractInfo from "../views/contract/index.vue";

import League from "../views/league/index.vue";

import GoodsItem from "../views/goodsItem/index.vue";
import User from "../views/user/index.vue";
import NewsType from "../views/newsType/index.vue";
import Auth from "../views/auth/index.vue";

import Technique from "../views/technique/index.vue";

import { getAuthorityPath } from "@/api/auth/auth.js";
import { getPath } from "@/views/menu.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/index",
    name: "Main",
    component: Main,
    children: [
      {
        path: "news",
        name: "newsInfo",
        component: newsInfo,
      },
     
      {
        path: "carbonTools",
        name: "carbonTools",
        component: carbonTools,
      },
      {
        path: "goodsInfo",
        name: "goodsInfo",
        component: goodsInfo,
      },
      {
        path: "greenProject",
        name: "greenProject",
        component: greenProject,
      },
      {
        path: "contract",
        name: "ContractInfo",
        component: ContractInfo,
      },
      {
        path: "league",
        name: "League",
        component: League,
      },
      {
        path: "goodsItem",
        name: "GoodsItem",
        component: GoodsItem,
      },
      {
        path: "newsType",
        name: "NewsType",
        component: NewsType,
      },
      {
        path: "auth",
        name: "Auth",
        component: Auth,
      },
      {
        path: "user",
        name: "User",
        component: User,
      },
      {
        path: "technique",
        name: "Technique",
        component: Technique,
      },
      {
        path: "picture",
        name: "picture",
        component: picture,
      },
    ],
  },
  {
    path: "/test",
    name: "test",
    component: test,
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

/**
 * 导航守卫
 */
router.beforeEach(async (to, from, next) => {
  // if (to.path != "/" && to.path.indexOf("test") < 0) {
  //   try {
  //     let route_now = to.path;
  //     let data = await getAuthorityPath();
  //     let flag = false;
     
  //     data.forEach((element) => {
  //       if (route_now == element.path) {
  //         flag = true;
  //       }
  //     });
  //     if (flag) {
  //       // 允许跳转
  //       next();
  //     } else {
  //       Message({
  //         message: "您没有权限,请联系管理员!",
  //         type: "error",
  //       });
  //       // 不允许跳转，需要调用 next(false) 或跳转到其他路由
  //       // next(false);
  //     }
  //   } catch (error) {}
  // } else {
    next();
  // }
});

//重复路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
