/**
 * 获取左侧按钮列表
 * @param {*} key
 * @returns
 */
export function getLeftButton(key) {
  let list = [];
  switch (key) {
    case "employee":
      list = [{ name: "复制", key: "copyUser" }];
      break;
    case "role":
      list = [
        { name: "添加", key: "addRole" },
        { name: "编辑", key: "editRole" },
        { name: "删除", key: "deleteRole" },
        { name: "复制", key: "copyRole" },
      ];
      break;

    default:
      break;
  }
  return list;
}
export function collectUniqueAuthIds(data) {
  const uniqueIds = [];

  // 遍历data数组
  data.forEach((item) => {
    // 如果auth为true且id不为空且不在uniqueIds数组中，则添加到uniqueIds数组
    if (item.auth && item.id && !uniqueIds.includes(item.id)) {
      uniqueIds.push(item.id);
    }

    // 递归检查children数组
    if (item.children && item.children.length > 0) {
      uniqueIds.push(...collectUniqueAuthIds(item.children));
    }
  });

  return uniqueIds;
}

/**
 * 获取当前权限数据
 * @param {*} auth
 * @returns
 */
export function baseAuthData(auth) {
  let data = [
    {
      id: "",
      name: "新闻资讯",
      key: "newsBasic",
      auth: false,
      checkedAuth: [],
      children: [
        { id: "", name: "新增", key: "add", auth: false },
        { id: "", name: "编辑", key: "edit", auth: false },
        { id: "", name: "删除", key: "delete", auth: false },
        { id: "", name: "导入", key: "import", auth: false },
        { id: "", name: "导出", key: "export", auth: false },
      ],
    },
    {
      id: "",
      name: "考勤登记",
      key: "carbonTools",
      auth: false,
      checkedAuth: [],

      children: [
        { id: "", name: "新增", key: "add", auth: false },
        { id: "", name: "编辑", key: "edit", auth: false },
        { id: "", name: "删除", key: "delete", auth: false },
        { id: "", name: "导入", key: "import", auth: false },
        { id: "", name: "导出", key: "export", auth: false },
      ],
    },
    {
      id: "",
      name: "薪资发放",
      key: "goodsInfo",
      auth: false,
      checkedAuth: [],

      children: [
        { id: "1", name: "新增", key: "add", auth: false },
        { id: "2", name: "编辑", key: "edit", auth: false },
        { id: "3", name: "删除", key: "delete", auth: false },
        { id: "4", name: "导入", key: "import", auth: false },
        { id: "5", name: "导出", key: "export", auth: false },
      ],
    },
    {
      id: "",
      name: "离职模块",
      key: "greenProject",
      auth: false,
      checkedAuth: [],

      children: [
        { id: "", name: "新增", key: "add", auth: false },
        { id: "", name: "编辑", key: "edit", auth: false },
        { id: "", name: "删除", key: "delete", auth: false },
        { id: "", name: "导入", key: "import", auth: false },
        { id: "", name: "导出", key: "export", auth: false },
      ],
    },
    {
      id: "",
      name: "合同信息模块",
      key: "contract",
      auth: false,
      checkedAuth: [],

      children: [
        { id: "", name: "新增", key: "add", auth: false },
        { id: "", name: "编辑", key: "edit", auth: false },
        { id: "", name: "删除", key: "delete", auth: false },
        { id: "", name: "导入", key: "import", auth: false },
        { id: "", name: "导出", key: "export", auth: false },
      ],
    },
    {
      id: "",
      name: "请假登记模块",
      key: "leaveReg",
      auth: false,
      checkedAuth: [],

      children: [
        { id: "", name: "新增", key: "add", auth: false },
        { id: "", name: "编辑", key: "edit", auth: false },
        { id: "", name: "删除", key: "delete", auth: false },
        { id: "", name: "导入", key: "import", auth: false },
        { id: "", name: "导出", key: "export", auth: false },
      ],
    },
    {
      id: "",
      name: "外出登记模块",
      key: "goodsItem",
      auth: false,
      checkedAuth: [],

      children: [
        { id: "", name: "新增", key: "add", auth: false },
        { id: "", name: "编辑", key: "edit", auth: false },
        { id: "", name: "删除", key: "delete", auth: false },
        { id: "", name: "导入", key: "import", auth: false },
        { id: "", name: "导出", key: "export", auth: false },
      ],
    },
    {
      id: "",
      name: "权限管理",
      key: "auth",
      auth: false,
      checkedAuth: [],

      children: [
        { id: "", name: "新增", key: "add", auth: false },
        { id: "", name: "编辑", key: "edit", auth: false },
        { id: "", name: "删除", key: "delete", auth: false },
        { id: "", name: "导入", key: "import", auth: false },
        { id: "", name: "导出", key: "export", auth: false },
      ],
    },
  ];

  data.forEach((element) => {
    if (auth[element.key] != null) {
      element.auth = true;
      element.children.forEach((ele) => {
        if (auth[element.key].includes(ele.key)) {
          ele.auth = true;
        }
      });
    }
  });

  return data;
}

/**
 * 获取人员列表
 * @param {*}
 * @returns
 */
export function getRoleList() {
  let empty = [
    { value: "1", label: "总管理" },
    { value: "1-1", label: "员工管理员" },
    { value: "2", label: "考勤管理员" },
    { value: "4", label: "离职管理员" },
    { value: "5", label: "保安管理员" },
    { value: "3", label: "bba" },
  ];
  return empty;
}

/**
 * 弹窗初始化数据(人员列表复制)
 * @param {*}
 * @returns
 */
export function userDialogData() {
  return [
    {
      title: "用户姓名",
      placeholder: "用户姓名",
      key: "tagIds",
      value: [],
      type: "selects",
      isNotNull: true,
      options: [],
    },
  ];
}

/**
 * 弹窗初始化数据(角色列表添加)
 * @param {*}
 * @returns
 */
export function addDialogData() {
  return [
    {
      title: "角色名称",
      placeholder: "角色名称",
      key: "name",
      value: "",
      type: "input",
      isNotNull: true,
    },
  ];
}

/**
 * 弹窗初始化数据(角色列表添加)
 * @param {*}
 * @returns
 */
export function copyDialogData() {
  return [
    {
      title: "用户姓名",
      placeholder: "用户姓名",
      key: "tagIds",
      value: [],
      type: "selects",
      isNotNull: true,
      options: [],
    },
  ];
}
