
const state = {
  activeMenu: {
    name: "",
  },
  position: "",
};

const mutations = {
  GET_ACTIVE_MENU: (state, activeMenu) => {
    state.activeMenu = activeMenu;
  },
  GET_POSITION: (state, position) => {
    state.position = position;
  },
  ADD_ACTIVE_MENU: (state, activeMenu) => {
    state.activeMenu = activeMenu;
  },
  ADD_POSITION: (state, position) => {
    state.position = position;
  },
  DEL_ACTIVE_MENU: (state, activeMenu) => {
    state.activeMenu = { name: ""};
  },
  DEL_POSITION: (state, position) => {
    state.position = '';
  },
};

const actions = {
  getActiveMenu({ commit }, activeMenu) {
    commit("GET_ACTIVE_MENU", activeMenu);
  },

  getPosition({ commit }, position) {
    commit("GET_POSITION", position);
  },

  addActiveMenu({ commit }, activeMenu) {
    commit("ADD_ACTIVE_MENU", activeMenu);
  },

  addPosition({ commit }, position) {
    commit("ADD_POSITION", position);
  },

  delActiveMenu({ commit }, activeMenu) {
    commit("DEL_ACTIVE_MENU", activeMenu);
  },

  delPosition({ commit }, position) {
    commit("DEL_POSITION", position);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
