/*
 * @Author: yang
 * @Date: 2024-07-17 09:27:35
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-13 13:56:25
 * @Description: 物品分类
 */

import request from '@/utils/request'
let baseUrl ='/carbon/itemGoodsType'

/**
 * @description: 获取物品分类列表
 * @return {*}
 */
export async function listPageGoodsItem(data) {
    return await request({
        url: baseUrl+'/listPage',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 添加物品分类
 * @return {*}
 */
export async function addGoodsItem(data) {
    return await request({
        url: baseUrl+'/add',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 编辑物品分类
 * @return {*}
 */
export async function updateGoodsItem(data) {
    return await request({
        url: baseUrl+'/update',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 批量删除物品分类
 * @return {*}
 */
export async function deleteGoodsItem(data) {
    return await request({
        url: baseUrl+'/deleteBatch',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}



