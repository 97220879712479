import { judegTime } from "../../utils/total";

let url = "/uploadFile"; //导入路径
export function initData() {
  return [
    {
      id: "1",
      jobNumber: "JV001198",
      displayName: "左婷婷",
      contractNumber: "ht22",
      contractStartDate: "2020-01-01",
      contractEndDate: "2020-01-01",
      contractStatus: "y",
      contractFile: "附件",
      des: "1XXXXXXXXXXXXXXXXXXXXXX",
    },
    {
      id: "2",
      jobNumber: "JV001198",
      displayName: "左婷婷",
      contractNumber: "ht22",
      contractStartDate: "2020-01-01",
      contractEndDate: "2020-01-01",
      contractStatus: "w",
      contractFile: "附件",
      des: "1XXXXXXXXXXXXXXXXXXXXXX",
    },
  ];
}

/**
 * 编辑的界面数据
 * @returns
 */
export function returnEditData() {
  return [
    {
      title: "员工",
      key: "displayName",
      value: "",
      type: "select",
      isNotNull: true,
    },
    {
      title: "合同编号",
      key: "contractNumber",
      value: "",
      type: "input-en-num",
      isNotNull: true,
    },
    {
      title: "合同开始日期",
      key: "contractStartDate",
      value: "",
      type: "date1",
      isNotNull: true,
    },
    {
      title: "合同结束日期",
      key: "contractEndDate",
      value: "",
      type: "date1",
      isNotNull: true,
    },
    {
      title: "合同状态",
      key: "contractStatus",
      value: "y",
      type: "radio",
      isNotNull: true,
      options: [
        { label: "生效中", value: "y" },
        { label: "已失效", value: "w" },
      ],
    },
    {
      title: "合同附件(仅支持上传pdf格式文件)",
      key: "contractFile",
      value: "",
      type: "upload1",
      isNotNull: true,
      fileName:"",
      fileList:[],
      uploadData: {
        action: process.env.VUE_APP_BASE_API + url,
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "合同附件",
          key: "filePath",
        },
      },
    },
    { title: "备注", key: "des", value: "", type: "textarea" },
  ];
}

/**
 * 弹框的基本数据
 * @returns
 */

export function returnDialogData() {
  return [
    {
      title: "合同模版下载",
      key: "model",
      value: "",
      type: "downloadBtn",
      isNotNull: false,
    },
    {
      title: "导入基本信息",
      key: "filePath",
      value: "",
      type: "upload",
      isNotNull: false,
      fileName:"",
      fileList: [],
      uploadData: {
        action: process.env.VUE_APP_BASE_API + url,
        headers: {
          Authorization:
            process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
        },
        data: {
          title: "合同信息",
          key: "filePath",
        },
      },
    },
  ];
}
