/*
 * @Author: yang
 * @Date: 2024-08-22 13:36:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-22 15:58:33
 * @Description: 文件注释:
 */


import request from '@/utils/request'
let baseUrl ='/carbon/user'

/**
 * @description: 获取用户信息
 * @return {*}
 */
export async function getAdminInfo(data) {
    return await request({
        url: baseUrl+'/getAdminInfo',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 修改管理员个人信息
 * @return {*}
 */
export async function updateAdmin(data) {
    return await request({
        url: baseUrl+'/updateAdmin',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 修改管理员密码（管理员）
 * @return {*}
 */
export async function updatePasswordAdmin(data) {
    return await request({
        url: baseUrl+'/updatePasswordAdmin',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}


/**
 * @description: 修改昵称和头像
 * @return {*}
 */
export async function saveUserInfo(data) {
    return await request({
        url: baseUrl+'/saveUserInfo',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}


