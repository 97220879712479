<template>
  <div id="main">
    <!-- 首页 -->
    <div class="left">
      <div class="logo">
        <!-- <i class="iconfont icon-zhengjianmoban"></i> -->
        <img src="../assets/img/logo.png" alt="" class="logo-img">
        <!-- <span>伏碳科技小程序后台管理系统</span> -->
      </div>
      <div class="menu customScrollBar">
        <div
          :class="{ item: true, 'is-active': activeMenu.active == item.active }"
          v-for="item in menu"
          @click="changeMenu(item)"
        >
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="t-l">
          <span> <i class="iconfont icon-xinwenzixun"></i>当前位置：</span>
          <span>{{ position }}</span>
        </div>
        <div class="t-r">
          <div class="name">尊敬的{{ nickName }},{{ Time }}</div>
          <div class="item" v-for="item in topButton">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="bottom"
            >
              <i :class="item.icon" @click="operate(item.active)"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="centent">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import * as MenuData from "./menu.js";
import * as user from "@/api/user/user.js";
export default {
  inject: ["reload"],
  data() {
    return {
      menu: MenuData.getMenuData(),
      activeMenu: {},
      topButton: [
        // { active: "uaser", name: "个人中心", icon: "el-icon-user" },
        { active: "exit", name: "注销", icon: "el-icon-switch-button" },
      ],
      position: "",
      Time: this.$total.getTimeOfDay(),
      nickName: "",
    };
  },
  methods: {
    changeMenu(menu) {
      this.$store.dispatch("sysData/getActiveMenu", menu);
      this.$store.dispatch("sysData/getPosition", menu.name);
      this.position = menu.name;
      this.activeMenu = menu;
      this.$router.push(menu.path);
    },
    operate(key) {
      switch (key) {
        case "exit":
          this.$store.dispatch("sysData/delActiveMenu");
          this.$store.dispatch("sysData/delPosition");
          localStorage.clear();
          this.$router.push("/");
          break;
        case "uaser":
          this.$store.dispatch("sysData/delActiveMenu");
          this.$store.dispatch("sysData/delPosition");
          this.$router.push("/index/user");
          break;

        default:
          break;
      }
    },
  },
  async mounted() {
    this.position = this.$store.state.sysData.position
      ? this.$store.state.sysData.position
      : this.menu[0].name;
    this.activeMenu = this.$store.state.sysData.activeMenu.name
      ? this.$store.state.sysData.activeMenu
      : this.menu[0];
    this.changeMenu(this.activeMenu);
    let res = await user.getAdminInfo({});
    this.nickName = res.nickName;
   
  },
};
</script>
<style lang="less" scoped>
#main {
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(to right, #dde5fa, #f5e1e3);

  > .left {
    width: 14%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #dcecfb, #f5f6fa);
    border-right: 3px solid #fff;

    > .logo {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 20px;

      > i {
        font-size: 2.3vw;
        color: #0274ed;
      }
      >.logo-img{
        width: 228px;
        height: 52px;
      }

      > span {
        font-size: 18px;
        margin-top: 20px;
        font-weight: 500;
        font-family: ddJinBuTi;
      }
    }

    > .menu {
      width: 100%;
      flex: 1;
      height: 0;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      box-sizing: border-box;

      > .item {
        width: 100%;
        height: 45px;
        min-height: 40px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 15px;
        color: #969899;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 8px;
        margin: 5px 0;

        > i {
          margin-right: 8px;
        }

        &:hover {
          background-color: #415fff;
          color: #fff;
        }
      }

      > .is-active {
        background-color: #415fff;
        color: #fff;
      }
    }
  }

  > .right {
    width: 0;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.3vw;
    box-sizing: border-box;

    > .top {
      width: 100%;
      height: 43px;
      display: flex;
      justify-content: space-between;

      > .t-l {
        > span {
          font-size: 14px;
          font-weight: 600;

          &:nth-child(1) {
            color: #969899;
          }

          > i {
            margin-right: 5px;
          }
        }
      }

      > .t-r {
        width: auto;
        display: flex;

        > .item {
          width: 30px;
          height: 30px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          cursor: pointer;
          color: #969899;
          margin: 0 5px;
          border-radius: 6px;
          cursor: pointer;
        }

        > .name {
          font-size: 14px;
          font-weight: 600;
          color: #969899;
          margin-right: 20px;
          line-height: 30px;
        }
      }
    }

    > .centent {
      width: 100%;
      height: 0;
      flex: 1;
      background-color: #fff;
      border-radius: 10px;
      // box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
