<template>
  <div id="greenProject">
    <!-- 环保项目模块 -->
    <diy-table
      :model="'greenProject'"
      :tableData="data.tableData"
      :tableMenu="data.tableMenu"
      :searchData="data.searchData"
      :searchButton="data.searchButton"
      :pageData="data.pageData"
      @showTableData="showTableData"
      @showSearchData="showSearchData"
      @showPageData="showPageData"
      @handleSearch="handleSearch"
      @handleClick="handleClick"
      @handleSelectionChange="handleSelectionChange"
    ></diy-table>

    <diy-dialog
      v-if="dialogData.flag"
      :title="dialogData.title"
      :width="dialogData.width"
      :maxHeight="dialogData.maxHeight"
      :dialogData="dialogData.data"
      :buttonList="dialogData.buttonList"
      @showDialogData="showDialogData"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
      @handleAvatarSuccess="handleAvatarSuccess"
      @handleError="handleError"
      @beforeAvatarUpload="beforeAvatarUpload"
      @downloadModel="downloadModel"
    ></diy-dialog>

    <diy-drawer
      v-if="drawerData.flag"
      :drawerData="drawerData.data"
      :title="drawerData.title"
      :buttonList="drawerData.buttonList"
      @showDrawerData="showDrawerData"
      @colseDrawer="colseDrawer"
      @handleDrawerButton="handleDrawerButton"
    ></diy-drawer>
  </div>
</template>
<script>
import * as greenProjectData from "./greenProject.js";
import * as greenProject from "@/api/greenProject/greenProject.js";
import * as CommonData from "@/api/common/common.js";
import { exportData } from "@/utils/requestPOST";
import { getAuthority } from "@/api/auth/auth.js";
import { getMenuBtn } from "@/views/menu.js";
export default {
  inject: ["reload"],
  data() {
    return {
      data: this.$base.initData("greenProject"),
      multipleSelection: [],
      dialogData: {
        tempData: {},
        flag: false,
        title: "",
        width: "",
        data: [],
        buttonList: [],
      },
      drawerData: {
        flag: false,
        width: "20%",
        title: "",
        data: [],
        buttonList: [],
      },
      staffListData: [], //下拉框数据
      userListData: [], //用户下拉框数据
      id: "",
    };
  },
  methods: {
    async initTableData() {
      let requestData = {
        pageSize: this.data.pageData.pageSize,
        currentPage: this.data.pageData.currentPage,
      };

      requestData = this.$total.array2json(this.data.searchData, requestData);
      let greenProjectData = await greenProject.greenProjectList(requestData);
      this.data.tableData = greenProjectData.records;
      this.data.pageData.total = greenProjectData.total;
    },
    /**
     * 顶部搜索按钮点击事件
     * @param {*} key
     */
    async handleSearch(key) {
      switch (key) {
        case "search":
          // 查询
          await this.initTableData();
          break;
        case "add":
          // 新增
          this.drawerData.title = "添加环保项目";
          this.drawerData.buttonList = [
            { name: "确定", key: "sureAddEmploy" },
            { name: "取消", key: "cancel" },
          ];
          this.drawerData.data = greenProjectData.returnEditData();
          this.drawerData.flag = true;
          break;
        case "edit":
          // 编辑
          if (
            this.multipleSelection.length == 0 ||
            this.multipleSelection.length > 1
          ) {
            this.$message({
              message: "请选择一条数据进行编辑!",
              type: "error",
            });
            return;
          }
          this.drawerData.title = "编辑环保项目信息";
          this.drawerData.buttonList = [
            { name: "确定", key: "sureUpdateEmploy" },
            { name: "取消", key: "cancel" },
          ];
          this.drawerData.data = greenProjectData.returnEditData();
          this.drawerData.data = this.$total.json2array(
            this.drawerData.data,
            this.multipleSelection[0]
          );
          this.drawerData.flag = true;
          break;
        case "delete":
          // 删除
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请至少选择一条数据进行删除操作!",
              type: "error",
            });
            return;
          }
          this.$confirm("此操作将永久删除所选的信息, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              let ids = "";
              this.multipleSelection.forEach((item) => {
                ids = item.id + "," + ids;
              });
              let requestData = { ids: ids };
              await greenProject.delgreenProject(requestData);
              await this.initTableData();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        default:
          break;
      }
    },
    /**
     * 表格按钮点击事件
     * @param {*} val
     */
    handleClick(val) {
      let row = val.row;
      let key = val.key;
    },
    /**
     * 弹出框的操作事件
     * @param {*} key
     */
    async dialogOper(key) {
      let requestData = {};
      let flag;
      switch (key) {
       
        case "cancel":
          this.colseDialog();

          break;

        default:
          break;
      }
    },
    /**
     * 抽屉按钮点击事件
     * @param {*} key
     */
    async handleDrawerButton(key) {
      let requestData = {};
      let flag;
      switch (key) {
        case "cancel":
          this.colseDrawer();
          break;
        case "sureAddEmploy":
          // 添加环保项目基础信息
          flag = this.$total.judegArrayNull(this.drawerData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          requestData = this.$total.array2json(
            this.drawerData.data,
            requestData
          );
          requestData.userId = requestData.displayName;
          await greenProject.addgreenProject(requestData);
          this.$message({
            message: "环保项目信息添加成功!",
            type: "success",
          });
          this.drawerData.flag = false;
          await this.initTableData();
          break;
        case "sureUpdateEmploy":
          // 确认修改环保项目信息
          flag = this.$total.judegArrayNull(this.drawerData.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          requestData = this.$total.array2json(
            this.drawerData.data,
            requestData
          );
          requestData.userId = this.multipleSelection[0].userId;
          requestData.id = this.multipleSelection[0].id;
          await greenProject.editgreenProject(requestData);
          this.$message({
            message: "环保项目修改成功!",
            type: "success",
          });
          this.drawerData.flag = false;
          await this.initTableData();
          break;

        default:
          break;
      }
    },
    /**
     * 添加或修改新闻资讯
     * @param {*} flag
     */
    async saveOrUpdateEmploy(flag) {},

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showTableData(val) {
      this.data.tableData = val;
    },
    showSearchData(val) {
      this.data.searchData = val;
    },
    showPageData(val) {
      this.data.pageData = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDrawer() {
      this.drawerData.flag = false;
    },
    showDrawerData(val) {
      this.drawerData.data = val;
    },
  },
  async mounted() {
    await this.initTableData();
  },
};
</script>
<style lang="less" scoped>
#greenProject {
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
