/*
 * @Author: yang
 * @Date: 2024-08-12 17:14:28
 * @LastEditors: 
 * @LastEditTime: 2024-08-23 11:03:11
 * @Description: 文件注释:
 */
import request from '@/utils/request'
let baseUrl = '/carbon/news'

/**
 * @description: 获取新闻资讯
 * @return {*}
 */
export async function newsList(data) {
    return await request({
        url: baseUrl + '/listPage',
        headers: {
            isToken: true
        },
        method: 'post',
        data: data,
        timeout: 20000
    })
}


/**
 * @description: 添加新闻资讯
 * @return {*}
 */
export async function addNews(data) {
    return await request({
        url: baseUrl + '/add',
        headers: {
            isToken: true
        },
        method: 'post',
        data: data,
        timeout: 20000
    })
}



/**
 * @description: 编辑新闻资讯
 * @return {*}
 */
export async function editNews(data) {
    return await request({
        url: baseUrl + '/update',
        headers: {
            isToken: true
        },
        method: 'post',
        data: data,
        timeout: 20000
    })
}

/**
 * @description: 批量删除新闻资讯
 * @return {*}
 */
export async function delNews(data) {
    return await request({
        url: baseUrl + '/deleteBatch',
        headers: {
            isToken: true
        },
        method: 'post',
        data: data,
        timeout: 20000
    })
}


