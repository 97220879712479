import Vue from "vue";
/**
 * 将数字转换成大写字母
 * @param {*} num
 */
export function numberToLetter(num) {
  return String.fromCharCode(num + 64);
}

/**
 * 数组转对象传值
 * @param {*} array
 * @param {*} json
 */
export function array2json(array, json) {
  array.forEach((element) => {
    Vue.set(json, element.key, element.value);
  });
  return json;
}

/**
 * 对象转数组传值
 * @param {*} array
 * @param {*} json
 */
export function json2array(array, json) {
  array.forEach((element) => {
    element.value = json[element.key];
    //当值为null时
    if (!json[element.key]) {
      element.value = "";
    }
  });
  console.log('array :>> ', array);
  return array;
}

/**
 * 对象数组去重
 * @param {*} array  对象数组
 * @param {*} object  要添加的对象
 */
export function deWeight(array, object) {
  let data = array;
  data.push(JSON.parse(JSON.stringify(object)));
  let set = new Set(data.map(JSON.stringify));
  array = Array.from(set).map(JSON.parse);
  return array;
}

/**
 * 判断当前字符串是否是数字
 * @param {*} str 要判断的字符串
 * 返回true 为数字型,包含小数
 * 返回false 为字符串型
 */
export function judgeStringIsNumber(str) {
  return !isNaN(str);
}

/**
 * 数字校验
 * @param {*} number
 */
export function formatMathDecimal(number) {
  var numReg = /^[\+\-]?\d*?\.?\d*?$|^([^0][0-9]+|0)$/;
  var numRe = new RegExp(numReg);
  if (!numRe.test(number)) {
    return false;
  }
  return true;
}

/**
 * 校验正整数
 * @param {*} number
 */
export function formatMathNumber(number) {
  var reg = /^[1-9]\d*$/;
  var numRe = new RegExp(reg);
  if (!numRe.test(number)) {
    return false;
  }
  return true;
}

/**
 * 仅输入字母与数字校验
 * @param {*} number
 */
export function formatLetterAndNumber(str) {
  var numReg = /^[a-zA-Z0-9_]+$/;
  var numRe = new RegExp(numReg);
  if (!numRe.test(str)) {
    return false;
  }
  return true;
}

/**
 * 填充数组中的下拉框options
 * @param {*} array
 * @param {*} key
 * @param {*} options
 */
export function fillArrayOptions(array, key, options) {
  array.forEach((element) => {
    if (element.key == key) {
      element.options = options;
    }
  });
}

/**
 * 填充数组中的默认值
 * @param {*} array
 * @param {*} key
 * @param {*} options
 */
export function fillArrayValue(array, key, val) {
  array.forEach((element) => {
    if (element.key == key) {
      element.value = val;
    }
  });
}

/**
 * 将接口获取的列表数据转换成下拉框所需的数据
 * @param {*} array
 */
export function changeKeyForArray(array) {
  let list = [];
  array.forEach((element) => {
    let dd = {
      value: element.id,
      label: element.name,
    };
    list.push(dd);
  });
  return list;
}

/**
 * 判断数组中不可为空的数据是否为空
 * @param {*} array
 */
export function judegArrayNull(array) {
  try {
    array.forEach((element) => {
      if (element.isNotNull) {
        if (typeof element.value == "string" && element.value == "") {
          throw element.title;
        }
        if (Array.isArray(element.value) && element.value.length == 0) {
          throw element.title;
        }
        if (typeof element.value == "undefined") {
          throw element.title;
        }
      }
    });
    return "ok";
  } catch (msg) {
    return msg;
  }
}

/**
 * 根据id值返回name值
 * @param {*} array 数组
 * @param {*} oldValue id的值
 * @param {*} key id
 * @param {*} newKey name
 */
export function searchValueByKey(array, oldValue, key, newKey) {
  let str = "";
  array.forEach((element) => {
    if (element[key] == oldValue) {
      str = element[newKey];
    }
  });
  return str;
}

/**
 * 根据key值将相关的信息内容关联如: 基本工资+计件工资=总计
 * @param {*} array
 */
export function sumTotal(array, oneKey, twoKey) {
  let one = "";
  let two = "";
  let sum = 0;
  array.forEach((element) => {
    if (element.key == oneKey) {
      one = parseFloat(element.value) ? parseFloat(element.value) : 0;
    }
    if (element.key == twoKey) {
      two = parseFloat(element.value) ? parseFloat(element.value) : 0;
    }
    sum = one + two;
  });
  return sum;
}

/**
 * 判断数组中开始时间和结束时间的大小
 * @param {*} array
 */
function compare(date1, date2) {
  let dates1 = new Date(date1);
  let dates2 = new Date(date2);
  if (dates1 < dates2) {
    return true;
  } else {
    return false;
  }
}

/**
 * 判断数组中取出时间
 * @param {*} array
 */
export function judegTime(array, key1, key2) {
  let start;
  let end;
  array.forEach((element) => {
    if (element.key == key1) {
      start = element.value;
    }
    if (element.key == key2) {
      end = element.value;
    }
  });
  let flag = compare(start, end);
  return flag;
}

/**
 * a,b两个数组，将b数组中的数据合并到a数组中
 * @param {*} array
 */
export function mergeArraysByKey(array1, array2) {
  // 遍历第一个数组
  array1.forEach((item1, index1) => {
    // 遍历第二个数组
    array2.forEach((item2) => {
      // 检查两个对象是否有相同的键
      Object.keys(item2).forEach((key) => {
        if (item1.hasOwnProperty(key)) {
          // 如果有相同的键，则替换第一个数组中的值
          array1[index1][key] = item2[key];
        }
      });
    });
  });
  return array1;
}

/**
 * 获取当前时间
 */
export function getTimeOfDay() {
  const date = new Date();
  const hours = date.getHours();

  if (hours >= 6 && hours < 12) {
    return "上午好 !";
  } else if (hours >= 12 && hours < 18) {
    return "下午好 !";
  } else if (hours >= 18 || hours < 6) {
    return "晚上好 !";
  }
}

/**
 * 处理数据转为下拉框
 */
export function convert(data) {
  let list = [];
  data.forEach((item) => {
    let dd = {
      value: item.typeId,
      label: item.typeName,
    };
    list.push(dd);
  });
  return list;
}

/**
 * 手机号校验
 * @param {*} value 
 */
// export function mobilePhoneNumberCheck(value) {
//   let mobilePhoneNumberReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
//   let phoneNumberReg = /^(\d{3,4}-)?\d{7,8}$/
//   let flag = true;
//   if (value == "" || value == undefined || value == null) {
//       flag = false;
//   } else {
//       if (!mobilePhoneNumberReg.test(value) &&  !phoneNumberReg.test(value)) {
//           flag = false;
//       } else {
//           flag = true;
//       }
//   }
//   return flag;
// }

export function mobilePhoneNumberCheck(value) {
  // 正则表达式匹配中国大陆的手机号码，以1开头，第二位是3-9之间的数字，后面跟着9个数字
  let mobilePhoneNumberReg = /^[1][3-9][0-9]{9}$/;
  // 正则表达式匹配固定电话号码，可以是3-4位区号（可选），然后是7-8位电话号码
  let landlinePhoneNumberReg = /^((\d{3,4}-)?\d{7,8}(-\d{1,4})?)$/;
  // 初始化验证标志为true
  let isValid = true;

  // 检查输入值是否为空、未定义或null
  if (value === "" || value === undefined || value === null) {
    isValid = false;
  } else {
    // 如果输入值不为空，检查是否匹配手机号码或固定电话号码的正则表达式
    if (!mobilePhoneNumberReg.test(value) && !landlinePhoneNumberReg.test(value)) {
      isValid = false;
    }
  }

  // 返回验证结果
  return isValid;
}


// /**
//  * 邮箱校验
//  * @param {*} value 
//  */
// export function mailCheck(value) {
//     let reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
//     let flag = true;
//     if (value == "" || value == undefined || value == null) {
//         flag = false;
//     } else {
//         if (!reg.test(value) && value != "") {
//             flag = false;
//         } else {
//             flag = true;
//         }
//     }
//     return flag;
// }