/*
 * @Author: yang
 * @Date: 2024-07-16 17:41:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-23 10:33:54
 * @Description: 合作与加盟
 */

import request from '@/utils/request'
let baseUrl ='/carbon/participate'

/**
 * @description: 获取合作与加盟列表
 * @return {*}
 */
export async function listleague(data) {
    return await request({
        url: baseUrl+'/listPage',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 添加合作加盟
 * @return {*}
 */
export async function addleague(data) {
    return await request({
        url: baseUrl+'/add',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 编辑合作加盟
 * @return {*}
 */
export async function editleague(data) {
    return await request({
        url: baseUrl+'/update',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}



/**
 * @description: 批量删除合作加盟信息
 * @return {*}
 */
export async function delleague(data) {
    return await request({
        url: baseUrl+'/deleteBatch',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}




