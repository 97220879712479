/*
 * @Author: yang
 * @Date: 2024-08-12 17:14:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-21 14:18:37
 * @Description: 文件注释:
 */
//注册全局公用组件
import Vue from "vue";

import DiyDialog from './diy_dialog/diyDialog'
Vue.component('diy-dialog', DiyDialog)

import DiyTable from './diy_table/diyTable.vue'
Vue.component('diy-table', DiyTable)

import DiyDrawer from './diy_drawer/diyDrawer.vue'
Vue.component('diy-drawer', DiyDrawer)

import Tinymce from './Tinymce/index.vue'
Vue.component('TinyMce', Tinymce)

import UeditorDialog from './ueditor_dialog/ueditorDialog.vue'
Vue.component('ueditor-dialog', UeditorDialog)