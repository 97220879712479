<!--
 * @Author: yang
 * @Date: 2024-08-12 17:14:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-21 16:42:32
 * @Description: 文件注释:
-->
<template>
  <div id="test">
    <!-- <TinyMce
      ref="tiny"
      v-model="mdlValue.fullText"
      :toolbar="toolbar"
      height="600px"
    ></TinyMce> -->

    <ueditor-dialog
      v-if="ueditorData.flag"
      :width="ueditorData.width"
      :title="ueditorData.title"
      :content="ueditorData.content"
    >
    </ueditor-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
     
      ueditorData: {
        flag: true,
        width: "60%",
        title: "nihao",
        content: "",
      },
    };
  },

  methods: {
    loadData(){

    }
    
  },
  mounted() {
    // this.loadData(); // 在组件挂载时加载数据
  },
};
</script>

<style lang="less" scoped>
#test {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  #test_echart {
    width: 1000px;
    height: 900px;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-code-img {
      height: 38px;
    }
  }
  .tu {
    width: 240px;
    height: 70px;
    // background: #423ba5;
  }
}
</style>
