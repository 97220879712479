<template>
  <div id="league">
    <!-- 合作与加盟 -->
    <diy-table
      :model="'league'"
      :tableData="data.tableData"
      :tableMenu="data.tableMenu"
      :searchData="data.searchData"
      :searchButton="data.searchButton"
      :pageData="data.pageData"
      @showTableData="showTableData"
      @showSearchData="showSearchData"
      @showPageData="showPageData"
      @handleSearch="handleSearch"
      @handleClick="handleClick"
      @handleSelectionChange="handleSelectionChange"
    ></diy-table>

    <diy-dialog
      v-if="dialogData.flag"
      :title="dialogData.title"
      :width="dialogData.width"
      :dialogData="dialogData.data"
      :buttonList="dialogData.buttonList"
      @showDialogData="showDialogData"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
      @handleAvatarSuccess="handleAvatarSuccess"
      @handleError="handleError"
      @beforeAvatarUpload="beforeAvatarUpload"
      @downloadModel="downloadModel"
    ></diy-dialog>

    <diy-drawer
      v-if="drawerData.flag"
      :drawerData="drawerData.data"
      :title="drawerData.title"
      :buttonList="drawerData.buttonList"
      @showDrawerData="showDrawerData"
      @colseDrawer="colseDrawer"
      @handleDrawerButton="handleDrawerButton"
    ></diy-drawer>
  </div>
</template>
<script>
import * as leagueData from "./league.js";
import * as league from "@/api/league/league.js";
import * as CommonData from "@/api/common/common.js";
import { exportData } from "@/utils/requestPOST";
import { getAuthority } from "@/api/auth/auth.js";
import { getMenuBtn } from "@/views/menu.js";
export default {
  inject: ["reload"],
  data() {
    return {
      data: this.$base.initData("league"),
      multipleSelection: [],
      dialogData: {
        tempData: {},
        flag: false,
        title: "",
        width: "",
        data: [],
        buttonList: [],
      },
      drawerData: {
        flag: false,
        width: "20%",
        title: "",
        data: [],
        buttonList: [],
      },
      staffListData: [], //员工下拉框数据
      userListData: [], //用户下拉框数据
      id: "",
    };
  },
  methods: {
    async initTableData() {
      let requestData = {
        pageSize: this.data.pageData.pageSize,
        pageNum: this.data.pageData.currentPage,
      };

      requestData = this.$total.array2json(this.data.searchData, requestData);
      let leagueListData = await league.listleague(requestData);
      this.data.tableData = leagueListData.records;
      this.data.pageData.total = leagueListData.total;
    },
    /**
     * 顶部搜索按钮点击事件
     * @param {*} key
     */
    async handleSearch(key) {
      switch (key) {
        case "search":
          // 查询
          await this.initTableData();
          break;
        case "edit":
          // 编辑
          if (
            this.multipleSelection.length == 0 ||
            this.multipleSelection.length > 1
          ) {
            this.$message({
              message: "请选择一条数据进行编辑!",
              type: "error",
            });
            return;
          }
          // this.drawerData.title = "编辑合作加盟";
          // this.drawerData.buttonList = [
          //   { name: "确定", key: "sureUpdateEmploy" },
          //   { name: "取消", key: "cancel" },
          // ];
          // this.drawerData.data = leagueData.returnEditData();
          // this.drawerData.data = this.$total.json2array(this.drawerData.data);
          // this.drawerData.flag = true;
          break;
        case "delete":
          // 删除
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请至少选择一条数据进行删除操作!",
              type: "error",
            });
            return;
          }
          this.$confirm("此操作将永久删除所选的信息, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              let ids = "";
              this.multipleSelection.forEach((item) => {
                ids = item.id + "," + ids;
              });
              let requestData = { ids: ids };
              await league.delleague(requestData);
              await this.initTableData();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        case "export":
          break;

        default:
          break;
      }
    },
    /**
     * 表格按钮点击事件
     * @param {*} val
     */
    handleClick(val) {
      let row = val.row;
      let key = val.key;
    },

    /**
     * 抽屉按钮点击事件
     * @param {*} key
     */
    async handleDrawerButton(key) {
      let requestData = {};
      let flag;
      let F;
      switch (key) {
        case "cancel":
          this.colseDrawer();
          break;
        case "sureAddEmploy":
          break;
        case "sureUpdateEmploy":
          break;

        default:
          break;
      }
    },
    /**
     * 添加或修改合作加盟
     * @param {*} flag
     */
    async saveOrUpdateEmploy(flag) {},

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showTableData(val) {
      this.data.tableData = val;
    },
    showSearchData(val) {
      this.data.searchData = val;
    },
    showPageData(val) {
      this.data.pageData = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDrawer() {
      this.drawerData.flag = false;
    },
    showDrawerData(val) {
      this.drawerData.data = val;
    },
   
  },
  async mounted() {
    
    await this.initTableData();
  },
};
</script>
<style lang="less" scoped>
#league {
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
