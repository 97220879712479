/*
 * @Author: yang
 * @Date: 2024-07-16 16:57:16
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-07-16 17:07:51
 * @Description: 合同信息模块
 */


import request from '@/utils/request'
let baseUrl ='/business/contract'

/**
 * @description: 获取合同信息模块列表
 * @return {*}
 */
export async function listContract(data) {
    return await request({
        url: baseUrl+'/listContract',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 添加合同信息
 * @return {*}
 */
export async function addContract(data) {
    return await request({
        url: baseUrl+'/addContract',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 编辑合同信息
 * @return {*}
 */
export async function editContract(data) {
    return await request({
        url: baseUrl+'/editContract',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 查询合同信息详情
 * @return {*}
 */
export async function detailsContract(data) {
    return await request({
        url: baseUrl+'/detailsContract',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 批量删除合同信息
 * @return {*}
 */
export async function delContract(data) {
    return await request({
        url: baseUrl+'/delContract',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 导入合同信息
 * @return {*}
 */
export async function importContract(data) {
    return await request({
        url: baseUrl+'/importContract',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}


