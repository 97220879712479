/*
 * @Author: yang
 * @Date: 2024-08-21 10:50:48
 * @LastEditors: 
 * @LastEditTime: 2024-08-21 10:51:32
 * @Description: 文件注释:
 */
// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols

const toolbar = [
    'searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen',
    'formatselect fontselect fontsizeselect'
  ]
  
  export default toolbar
  
  