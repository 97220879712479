/*
 * @Author: yang
 * @Date: 2024-08-12 17:14:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-13 11:19:23
 * @Description: 文件注释:
 */
/**
 * 模拟接口数据
 */
export function initData() {
  return [
    {
      id: "1",
      jobNumber: "JV001198",
      displayName: "左婷婷",
      goRecordNumber: "jt011",
      goRegTime: "2020-01-01",
      comeRegTime: "2020-01-01",
      goAddress: "马尔代夫",
      goReason: "度假",
      contactInfo: "邮件",
      des: "1XXXXXXXXXXXXXXXXXXXXXX",
    },
    {
      id: "2",
      jobNumber: "JV001198",
      displayName: "左婷婷",
      goRecordNumber: "jt011",
      goRegTime: "2020-01-01",
      comeRegTime: "2020-01-01",
      goAddress: "马尔代夫",
      goReason: "度假",
      contactInfo: "邮件",
      des: "1XXXXXXXXXXXXXXXXXXXXXX",
    },
  ];
}

/**
 * 编辑的界面数据
 * @returns
 */
export function returnEditData() {
  return [
    {
      title: "物品类别排序",
      key: "sort",
      value: "",
      type: "input-number",
      isNotNull: true,
    },
    {
      title: "物品类别名称",
      key: "typeName",
      value: "",
      type: "input",
      isNotNull: true,
    },
    
  ];
}

