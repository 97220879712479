/*
 * @Author: yang
 * @Date: 2024-07-15 11:09:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-13 14:54:13
 * @Description: 公共的请求
 */

import request from "@/utils/request";
let baseUrl = "/system/dept";

/**
 * @description: 获取物品分类---下拉框
 * @return {*}
 */

export async function listPageGoodsItem(data) {
  return await request({
    url: "/carbon/itemGoodsType/listPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}


