/**
 *
 * @returns
 */
export function getMenuData() {
  let menu = [
    {
      name: "新闻资讯",
      id: "000",
      active: "newsBasic",
      path: "/index/news",
      icon: "iconfont icon-xinwenzixun",
    },
    {
      name: "新闻类型",
      id: "000",
      active: "newsType",
      path: "/index/newsType",
      icon: "iconfont icon-xinwenleixing",
    },
    {
      name: "碳转换装备",
      id: "000",
      active: "carbonToolsRegistration",
      path: "/index/carbonTools",
      icon: "iconfont icon-zhuangbei",
    },
    {
      name: "碳转化技术",
      id: "000",
      active: "technique",
      path: "/index/technique",
      icon: "iconfont icon-tanzhonghe",
    },
    {
      name: "物品信息",
      id: "000",
      active: "payroll",
      path: "/index/goodsInfo",
      icon: "iconfont icon-wupinxinxi",
    },
    {
      name: "物品分类",
      id: "000",
      active: "goodsItemistration",
      path: "/index/goodsItem",
      icon: "iconfont icon-wupinfenlei",
    },
    {
      name: "环保项目",
      id: "000",
      active: "quit",
      path: "/index/greenProject",
      icon: "iconfont icon-a-environmental",
    },
    
    {
      name: "合作与加盟",
      id: "000",
      active: "league",
      path: "/index/league",
      icon: "iconfont icon-hezuo",
    },
   
    {
      name: "轮播图",
      id: "000",
      active: "picture",
      path: "/index/picture",
      icon: "iconfont icon-lunbotu1",
    },
    {
      name: "个人信息",
      id: "000",
      active: "user",
      path: "/index/user",
      icon: "iconfont icon-gerenxinxi",
    },
  ];
  return menu;
}

export function getMenuByPath(path) {
  let menu = {};
  let list = getMenuData();
  list.forEach((element) => {
    if (element.path == path) {
      menu = element;
    }
  });
  return menu;
}

//处理路由
export function getPath(a) {
  let arr = [];
  a.forEach((item) => {
    arr.push(item.path);
  });
  return arr;
}

//处理按钮
export function getMenuBtn(a, b) {
  let arr = [];
  a.forEach((item) => {
    arr.push(item.name);
  });
  let c = b.filter((item) => arr.includes(item.title));

  return c;
}

//处理菜单
export function getMenu(a, b) {
  let arr = [];
  a.forEach((item) => {
    arr.push(item.name);
  });
  let c = b.filter((item) => arr.includes(item.name));
  c.forEach((item) => {
    a.forEach((i) => {
      if (item.name == i.name) {
        item.id = i.id;
        return;
      }
    });
  });
  return c;
}
