/*
 * @Author: yang
 * @Date: 2024-07-11 09:38:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-14 19:42:34
 * @Description: 请填写简介
 */
/**
 * 模拟接口数据
 */
export function initData() {
  return [];
}

/**
 * 编辑的界面数据
 * @returns
 */
export function returnEditData() {
  return [
    {
      title: "环保项目排序",
      key: "sort",
      value: "",
      type: "input-number",
      isNotNull: true,
    },
    {
      title: "环保名称",
      key: "name",
      value: "",
      type: "input",
      isNotNull: true,
    },
    {
      title: "单位",
      key: "unit",
      value: "",
      type: "input",
      isNotNull: true,
    },
    {
      title: "每单位节约排碳量",
      key: "carbonEmissions",
      value: "",
      type: "input",
      isNotNull: true,
    },
  ];
}
