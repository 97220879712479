

import request from '@/utils/request'
let baseUrl ='/carbon/systemConfig'

/**
 * @description: 碳转化装备
 * @return {*}
 */
export async function getTechnique(data) {
    return await request({
        url: baseUrl+'/getOneByKey',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 添加修改碳转化装备
 * @return {*}
 */
export async function saveOrUpdate(data) {
    return await request({
        url: baseUrl+'/saveOrUpdate',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

