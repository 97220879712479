/*
 * @Author: yang
 * @Date: 2024-07-25 09:33:12
 * @LastEditors: 
 * @LastEditTime: 2024-08-01 17:02:03
 * @Description: 请填写简介
 */


// 引入axios库
import axios from "axios";

// 定义导出数据的函数
export async function exportData(data,requrl,title) {
  try {
    // 设置请求的URL和参数
    const url =
      process.env.VUE_APP_BASE_API + requrl;
    const params = {
      method: "post",
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("isToken"),
      },
      responseType: "blob", // 设置响应类型为blob，以便接收文件流
    };

    // 发送POST请求
    const response = await axios(params);

    // 处理响应
    if (response.status === 200) {
      // 创建一个Blob对象，用于存储文件流
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // 创建一个a标签，用于触发下载
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = title+".xlsx"; // 设置下载的文件名和扩展名
      link.click(); // 触发下载
    } else {
      console.error("数据导出失败");
    }
  } catch (error) {
    console.error("发生错误：", error);
  }
}
