/*
 * @Author: yang
 * @Date: 2024-08-12 17:14:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-22 10:10:44
 * @Description: 文件注释:
 */
/**
 * 模拟接口数据
 */
export function initData() {
  return [];
}

/**
 * 编辑的界面数据
 * @returns
 */
export function returnEditData() {
  return [
    {
      title: "物品类别排序",
      key: "sort",
      value: "",
      type: "input-number",
      isNotNull: true,
    },
    {
      title: "物品类别名称",
      key: "typeName",
      value: "",
      type: "input",
      isNotNull: true,
    },
  ];
}
