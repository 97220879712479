/*
 * @Author: yang
 * @Date: 2024-08-12 17:14:28
 * @LastEditors: 
 * @LastEditTime: 2024-08-13 16:23:10
 * @Description: 文件注释:
 */

import request from '@/utils/request'
let baseUrl ='/carbon/itemGoods'

/**
 * @description: 物品信息
 * @return {*}
 */
export async function listPage(data) {
    return await request({
        url: baseUrl+'/listPage',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 添加物品信息
 * @return {*}
 */
export async function addGoodsInfo(data) {
    return await request({
        url: baseUrl+'/add',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}

/**
 * @description: 编辑物品信息
 * @return {*}
 */
export async function updateGoodsInfo(data) {
    return await request({
        url: baseUrl+'/update',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}


/**
 * @description: 批量删除物品信息
 * @return {*}
 */
export async function deleteGoodsInfo(data) {
    return await request({
        url: baseUrl+'/deleteBatch',
        headers: {
            isToken: true
        },
        method: 'post',
        data:data,
        timeout: 20000
    })
}



