import axios from 'axios';
import router from '@/router'
import { MessageBox, Message, Loading } from 'element-ui'
import Vue from 'vue'
//记录当前需要显示加载动画的请求数量
let needLoadingRequestCount = 0;
// 全局loading相关代码
let loading; //定义loading变量
//创建loading
function startLoading() {
    loading = Loading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.5)",
    });
}


//清除loading
async function endLoading() {
    loading.close();
}

/**
 * 开启loading
 */
let showFullScreenLoading = () => {
    if (needLoadingRequestCount === 0) {
        startLoading();
    }
    needLoadingRequestCount++;
};

let tryHideFullScreenLoading = () => {
    //至少运行300ms才关闭
    new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, 10);
    }).then(
        () => {
            needLoadingRequestCount--;
            if (needLoadingRequestCount <= 0) {
                endLoading();
            }
        },
        () => {}
    );
};

export async function request(config) {

    const instance = axios.create({
        baseURL: process.env.VUE_APP_BASE_API,
        timeout: 5000,
    });
    // 请求拦截
    instance.interceptors.request.use(
        (config) => {
            showFullScreenLoading();
            // 添加Token等处理逻辑
            // console.log(config);
            const isToken = (config.headers || {}).isToken === false
            if (!isToken) {
                config.headers['Authorization'] = process.env.VUE_APP_TOKEN + localStorage.getItem("isToken") // 让每个请求携带自定义token 请根据实际情况自行修改
            }
            // get请求映射params参数
            if (config.method === 'get' && config.data) {
                let url = config.url + '?' + tansParams(config.data);
                url = url.slice(0, -1);
                config.params = {};
                config.url = url;
            }
            return config;
        },
        (error) => {
            tryHideFullScreenLoading();
            return Promise.reject(error);
        }
    );

    // 响应拦截
    instance.interceptors.response.use(
        (response) => {
            //对应的是下方 await instance(config); 的返回值
            tryHideFullScreenLoading();
            return response.data;
        },
        (error) => {
            console.log("请求失败", error);
            tryHideFullScreenLoading();
            return Promise.reject(error);
        }
    );

    try {
        const res = await instance(config);

        switch (res.code) {
            case "200":
                return res.result
                break;

            case "500":
                throw res;
                break;
            case "401":
                MessageBox.alert('您的登录已过期,请重新登录!', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        window.localStorage.clear()
                        router.push("/");
                    }
                });
                // throw res;
                break;

            default:
                throw res;
                break;
        }
    } catch (error) {
        Message.warning(error.message)
        throw error;
    }
}

function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && value !== "" && typeof(value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && value[key] !== "" && typeof(value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}

export default request;