<template>
  <div id="diy-table">
    <div class="search">
      <div class="s-l">
        <div class="item" v-for="item in searchData">
          <el-input
            v-if="item.type == 'input'"
            :clearable="item.clearable"
            v-model="item.value"
            :placeholder="item.title"
          ></el-input>
          <el-select
            v-if="item.type == 'select'"
            v-model="item.value"
            :clearable="item.clearable"
            :placeholder="'请选择' + item.title"
          >
            <el-option
              v-for="op in item.options"
              :key="op.value"
              :label="op.label"
              :value="op.value"
            >
            </el-option>
          </el-select>
          <el-date-picker
            v-if="item.type == 'date-m'"
            v-model="item.value"
            type="month"
            :placeholder="'请选择' + item.title"
            :clearable="item.clearable"
            value-format="'yyyy-MM"
          >
          </el-date-picker>
          <el-date-picker
              v-if="item.type == 'date1'"
              clearable
              v-model="item.value"
              type="date"
              format="yyyy 年 MM 月 dd 日 "
              value-format="yyyy-MM-dd"
              :placeholder="'请选择' + item.title"
            >
            </el-date-picker>
          <el-date-picker
            v-if="item.type == 'date-d-r'"
            v-model="item.value"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :clearable="item.clearable"
            value-format="'yyyy-MM-dd"
          >
          </el-date-picker>
          <el-date-picker
            v-if="item.type == 'ymdt_time'"
            v-model="item.value"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
          <el-date-picker
            v-if="item.type == 'ymdhms_time'"
            v-model="item.value"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
          <el-autocomplete
            v-if="item.type == 'autocomplete'"
            :clearable="item.clearable"
            class="inline-input"
            v-model="item.showText"
            :fetch-suggestions="
              (str, cb) => {
                handleAutocompleteSelect(item.options, str, cb);
              }
            "
            value-key="name"
            :placeholder="item.title"
            :trigger-on-focus="false"
            @select="
              (val) => {
                item.value = val.id;
              }
            "
          ></el-autocomplete>
        </div>
        <div class="but" @click="handleSearch('search')">查询</div>
      </div>
      <div class="s-r">
        <div
          class="but"
          v-for="but in searchButton"
          @click="handleSearch(but.key)"
        >
          <i :class="but.icon"></i>{{ but.title }}
        </div>
      </div>
    </div>
    <div class="content">
      <el-table
        :data="data"
        stripe
        style="width: 100%"
        height="100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" v-if="isSelect">
        </el-table-column>
        <el-table-column label="序号" type="index" width="55">
        </el-table-column>
        <el-table-column
          :prop="menu.key"
          :label="menu.title"
          :width="menu.width"
          v-for="(menu, index) in tableMenu"
          :key="index"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="menu.type == 'showHtml'"
              v-html="scope.row[menu.key]"
            ></span>
            <div
              class="link"
              v-else-if="menu.type == 'link'"
              @click="handleClick(scope.row, menu.key, menu.linkPath)"
            >
              {{ menu.showText }}
            </div>
            <div
              class="link"
              v-else-if="menu.type == 'text-link'"
              :style="{
                color: menu.isChangeColor
                  ? returnStatusColor(menu.key, scope.row[menu.key])
                  : '',
              }"
              @click="handleClick(scope.row, menu.key, menu.linkPath)"
            >
              {{
                menu.isChangeText
                  ? returnStatusText(menu.key, scope.row[menu.key])
                  : scope.row[menu.key] == null || scope.row[menu.key] == ""
                  ? "-"
                  : scope.row[menu.key]
              }}
            </div>
            <div v-else-if="menu.type == 'show-img'">
              <div>
                <img
                  :src="scope.row[menu.key] ? scope.row[menu.key] : ''"
                  alt=""
                  style="width: 30px; height: 30px"
                />
              </div>
            </div>
            <span v-else>
              <div
                :style="{
                  color: menu.isChangeColor
                    ? returnStatusColor(menu.key, scope.row[menu.key])
                    : '',
                }"
              >
                {{
                  menu.isChangeText
                    ? returnStatusText(menu.key, scope.row[menu.key])
                    : scope.row[menu.key] == null || scope.row[menu.key] == ""
                    ? "-"
                    : scope.row[menu.key]
                }}
              </div>
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="操作" width="100" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row, but)" type="text" size="small"
                            v-for="but in scope.row.buttonList">{{ but }}</el-button>
                    </template>
                </el-table-column> -->
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pData.currentPage"
        :page-sizes="pData.pageSizes"
        :page-size="pData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "diy_table",
  props: {
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    tableMenu: {
      type: Array,
      default: function () {
        return [];
      },
    },
    searchData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    searchButton: {
      type: Array,
      default: function () {
        return [];
      },
    },
    isSelect: {
      // 是否携带多选框
      type: Boolean,
      default: true,
    },
    pageData: {
      type: Object,
      default: function () {
        return {
          currentPage: 1,
          total: 30,
          pageSizes: [10, 20, 50, 100],
          pageSize: 10,
        };
      },
    },
    model: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: this.tableData,
      sData: this.searchData,
      pData: this.pageData,
    };
  },

  watch: {
    tableData() {
      this.data = this.tableData;
    },
    data(newValue) {
      this.$emit("showTableData", this.data);
    },
    sData(newValue) {
      this.$emit("showSearchData", this.sData);
    },
    searchData() {
      this.sData = this.searchData;
    },
    pData(newValue) {
      this.$emit("showPageData", this.pData);
    },
    pageData() {
      this.pData = this.pageData;
    },
  },
  methods: {
    /**
     * 顶部搜索中的按钮点击事件
     * @param {*} key
     */
    handleSearch(key) {
      this.$emit("handleSearch", key);
    },
    /**
     * 表格按钮点击事件
     * @param {*} row
     * @param {*} key
     */
    handleClick(row, key, linkPath) {
      this.$emit("handleClick", { row, key, linkPath });
    },
    /**
     * 多选框事件
     * @param {*} val
     */
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
    handleSizeChange(val) {
      this.pData.pageSize = val;
      this.handleSearch("search");
    },
    handleCurrentChange(val) {
      this.pData.currentPage = val;
      this.handleSearch("search");
    },
    handleAutocompleteSelect(array, str, cb) {
      var restaurants = array;
      var results = str
        ? restaurants.filter(this.createFilter(str))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0
        );
      };
    },
    handleSelect(item) {},

    returnStatusColor(key, status) {
      return this.$base.getStatusNameByNum("color_" + this.model + key, status);
    },
    returnStatusText(key, status) {
      return this.$base.getStatusNameByNum(this.model + key, status);
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
#diy-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;

  > .search {
    width: 100%;
    height: auto;
    min-height: 45px;
    display: flex;
    justify-content: space-between;

    > .s-l {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;

      > .item {
        width: 220px;
        margin-right: 8px;
        height: 30px;
        min-height: 30px;
        display: flex;
        align-items: center;

        /deep/.el-input,
        /deep/.el-input__inner,
        /deep/.el-select {
          width: 100%;
          height: 30px;
          line-height: 30px;
        }

        /deep/.el-input__prefix,
        /deep/.el-input__icon,
        /deep/ .el-date-editor .el-range-separator {
          line-height: 30px;
          display: flex;
          align-items: center;
        }

        /deep/.el-input__inner {
          border-radius: 4px;
        }

        /deep/ .el-date-editor .el-range-input {
          background-color: #f1f1f100;
        }
      }
    }

    > .s-r {
      width: auto;
      display: flex;
      align-items: center;
    }
  }

  > .content {
    width: 100%;
    height: 0;
    flex: 1;

    .link {
      color: #415fff;
      cursor: pointer;
    }
  }

  .but {
    width: auto;
    height: 30px;
    background-color: #415fff;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 8px;

    > i {
      margin-right: 5px;
    }
  }
}
</style>
