<template>
  <div id="diy-drawer">
    <!-- 自定义抽屉组件 -->
    <div class="perch-active" @click="colseDrawer"></div>
    <div class="content" @click.stop="clickNull" :style="{ width: width }">
      <div class="title"><i class="el-icon-s-operation"></i>{{ title }}</div>
      <div class="con customScrollBar">
        <div class="line" v-for="item in data">
          <div class="title">
            <span v-if="item.isNotNull">*</span>{{ item.title }}：
          </div>
          <div class="value">
            <el-input-number
              v-if="item.type == 'input-number'"
              v-model="item.value"
              @change="handleChange"
              :min="1"
              :max="100"
              :label="'请输入' + item.title"
            ></el-input-number>
            <el-input
              v-if="item.type == 'phone'"
              v-model="item.value"
              maxlength="11"
              show-word-limit
              clearable
              :label="'请输入' + item.title"
              @blur="
                (e) => {
                  validatePhone(item.value, item.key);
                }
              "
            ></el-input>
            <el-input
              v-if="item.type == 'input'"
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
            ></el-input>
            <el-input
              v-if="item.type == 'ypsw'"
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
              :disabled="true"
            ></el-input>
            <el-input
              v-if="item.type == 'psw'"
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
              show-password
            ></el-input>
            <!-- 不可输入汉字 -->
            <el-input
              v-if="item.type == 'input-en-num'"
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
              @input="
                (e) => {
                  validateInput(e, item.key);
                }
              "
            ></el-input>
            <el-input
              v-if="item.type == 'input-num'"
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
              @change="
                (e) => {
                  changeNum(e, item.key);
                }
              "
            ></el-input>
            <el-input
              v-if="item.type == 'input-num-text'"
              v-model="totalMoney"
              :placeholder="'请输入' + item.title"
              clearable
              :disabled="true"
              @change="
                (e) => {
                  changeNum(e, item.key);
                }
              "
            ></el-input>
            <el-input
              v-if="item.type == 'textarea'"
              type="textarea"
              :rows="4"
              :maxlength="1000"
              show-word-limit
              v-model="item.value"
              :placeholder="'请输入' + item.title"
              clearable
            ></el-input>
            <el-radio-group v-if="item.type == 'radio'" v-model="item.value">
              <el-radio
                :label="op.value"
                v-for="op in item.options"
                :key="op.label"
                >{{ op.label }}</el-radio
              >
            </el-radio-group>
            <el-select
              v-if="item.type == 'select'"
              v-model="item.value"
              clearable
              filterable
              :placeholder="'请选择' + item.title"
            >
              <el-option
                v-for="op in item.options"
                :key="op.value"
                :label="op.label"
                :value="op.value"
              >
              </el-option>
            </el-select>
            <el-date-picker
              v-if="item.type == 'dateTime'"
              clearable
              v-model="item.value"
              type="datetime"
              :placeholder="'请选择' + item.title"
              :picker-options="pickerOptionsDateTime"
            >
            </el-date-picker>
            <el-date-picker
              v-if="item.type == 'date'"
              clearable
              v-model="item.value"
              type="date"
              format="yyyy 年 MM 月 dd 日 "
              value-format="yyyy-MM-dd"
              :placeholder="'请选择' + item.title"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-date-picker
              v-if="item.type == 'date1'"
              clearable
              v-model="item.value"
              type="date"
              format="yyyy 年 MM 月 dd 日 "
              value-format="yyyy-MM-dd"
              :placeholder="'请选择' + item.title"
            >
            </el-date-picker>
            <el-date-picker
              v-if="item.type == 'month'"
              v-model="item.value"
              type="month"
              format="yyyy 年 MM 月"
              value-format="yyyy-MM"
              :placeholder="'请选择' + item.title"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <!-- 上传文件 -->
            <el-upload
              class="upload-demo"
              v-if="item.type == 'upload'"
              :action="item.uploadData.action"
              :headers="item.uploadData.headers"
              :show-file-list="true"
              :file-list="item.fileList"
              :multiple="false"
              :data="item.uploadData.data"
              :before-upload="beforeUploadContractFile"
              :on-success="handleSuccessContractFile"
              :on-error="handleErrorContractFile"
            >
              <el-button size="small" type="primary">选择文件</el-button>
              <!-- <div class="el-upload__tip" slot="tip">
                只能上传xlsx,xls文件，且不超过500kb
              </div> -->
            </el-upload>
            <el-upload
              class="upload-demo"
              v-if="item.type == 'upload1'"
              :action="item.uploadData.action"
              :headers="item.uploadData.headers"
              :show-file-list="false"
              :multiple="false"
              :data="item.uploadData.data"
              :before-upload="beforeUploadContractFile"
              :on-success="handleSuccessContractFile"
              :on-error="handleErrorContractFile"
            >
              <el-button size="small" type="primary">选择文件</el-button>
              <div class="el-upload__tip" slot="tip" style="font-size: 14px">
                {{ item.fileName }}
              </div>
            </el-upload>
            <el-upload
              class="upload-demo"
              v-if="item.type == 'upload-img'"
              :action="item.uploadData.action"
              :headers="item.uploadData.headers"
              :show-file-list="false"
              multiple
              :data="item.uploadData.data"
              :before-upload="beforeUploadImg"
              :on-success="handleSuccessImg"
              :on-error="handleErrorImg"
            >
              <div class="upimg">
                <div class="btnupload">
                  <el-button size="small" type="primary">选择图片</el-button>
                </div>

                <div
                  class="coverimg"
                  v-for="(img, index) in item.imgList"
                  :key="index"
                >
                  <img :src="img" alt="" />
                  <div class="mb">
                    <i class="el-icon-edit" @click="deleteImg(item, index)"></i>
                  </div>
                </div>
              </div>
            </el-upload>

            <div class="richtext" v-if="item.type == 'richtext'">
              <span @click="openUeditor(item)">查看详情</span>
            </div>
          </div>
        </div>
      </div>
      <div class="buts">
        <div
          class="but"
          v-for="but in buttonList"
          @click="handleDrawerButton(but.key)"
        >
          {{ but.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "diy_drawer",
  props: {
    drawerData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    buttonList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    width: {
      type: String,
      default: "20%",
    },
    title: {
      type: String,
      default: "20%",
    },
  },
  data() {
    return {
      data: this.drawerData,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pickerOptionsDateTime: {
        disabledDate(time) {
          let oneDay = 86400000; // 一天的毫秒数
          let currentDate = new Date(); // 当前时间
          let oneDayAgo = currentDate.getTime() - oneDay; // 当前时间减去一天的时间戳
          return time.getTime() < oneDayAgo;
        },
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
    };
  },
  watch: {
    drawerData() {
      this.data = this.drawerData;
    },
    data(newValue) {
      this.$emit("showDrawerData", this.data);
    },
  },
  computed: {
    //工资计算(求和)
    totalMoney() {
      let totalMoney = this.$total.sumTotal(this.data, "basicPay", "piecePay");
      totalMoney = totalMoney.toFixed(2);
      return totalMoney;
    },
  },
  methods: {
    openUeditor(item) {
      this.$emit("openUeditor", item);
    },
    handleChange(value) {},
    //输入框只可输入字母加数字
    validateInput(val, key) {
      if (!this.$total.formatLetterAndNumber(val)) {
        this.data.forEach((element) => {
          if (element.key == key) {
            element.value = "";
          }
        });
        this.$message({
          message: "仅支持字母和数字!",
          type: "error",
        });
      }
    },
    //输入框校验手机号
    validatePhone(val, key) {
      if (!this.$total.mobilePhoneNumberCheck(val)) {
        this.data.forEach((element) => {
          if (element.key == key) {
            element.isTrue = false;
          }
        });
      } else {
        this.data.forEach((element) => {
          if (element.key == key) {
            element.isTrue = true;
          }
        });
      }
    },
    clickNull() {},
    colseDrawer() {
      this.$emit("colseDrawer");
    },
    changeNum(val, key) {
      if (!this.$total.judgeStringIsNumber(val)) {
        this.data.forEach((element) => {
          if (element.key == key) {
            element.value = "";
          }
        });
        this.$message({
          message: "仅支持数字型!",
          type: "error",
        });
      }
    },
    handleDrawerButton(key) {
      this.$emit("handleDrawerButton", key);
    },
    handleSuccessContractFile(response, file, fileList) {
      this.$emit("handleSuccessContractFile", response, file, fileList);
    },
    handleErrorContractFile(err, file, fileList) {
      console.log("文件isToken: true失败", err);
      this.$emit("handleErrorContractFile");
    },
    beforeUploadContractFile(file) {
      let flag;
      this.$emit("beforeUploadContractFile", file, (res) => {
        flag = res;
      });

      return flag;
    },

    //图片上传
    beforeUploadImg(file) {
      console.log(file);
      this.$emit("beforeUploadImg", file);
    },
    handleSuccessImg(response, file, fileList) {
      console.log("图片上传成功 :>> ", response, file, fileList);
      this.$emit("handleSuccessImg", response, file, fileList);
    },
    handleErrorImg(err, file, fileList) {
      console.log("文件上传失败", err);
      let flag;
      this.$emit("handleErrorImg", file, (res) => {
        flag = res;
      });
      return flag;
    },
    deleteImg(item, index) {
      this.$emit("deleteImg", item, index);
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
#diy-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #00000049;
  z-index: 90;
  overflow: hidden;
  display: flex;
  transition: 0.3s;

  > .content {
    background-color: #f8f8f8;
    height: 100%;
    width: 500px;
    animation-name: transformAnimation;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    padding: 1vw;
    box-sizing: border-box;

    > .title {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e2e2e2;
      display: flex;
      align-items: center;
      font-family: 15px;
      font-weight: 600;

      > i {
        margin-right: 8px;
      }
    }

    > .con {
      width: 100%;
      height: 0;
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      background-color: #f8fbff;
      margin-top: 8px;
      padding: 6px;
      box-sizing: border-box;

      > .line {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 13px;

        > .title {
          font-size: 13px;
          margin-bottom: 5px;
          margin-left: 5px;

          > span {
            color: #fb5102;
            font-size: 17px;
            margin-right: 5px;
          }
        }
        .upimg {
          // width: 100%;
          // min-height: 150px;
          background-color: #f6f9ff;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          position: relative;
          cursor: pointer;

          .btnupload {
            width: 80px;
            height: 32px;
            margin-bottom: 10px;
          }
          .coverimg {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            border-radius: 5px;
            overflow: hidden;
            &:hover {
              .mb {
                bottom: 0px;
                transition: 0.5s;
              }
            }

            > img {
              height: 100%;
            }

            i {
              color: #fff;
              font-size: 20px;
            }

            > .mb {
              width: 100%;
              position: absolute;
              bottom: -40px;
              height: 40px;
              background-color: #2e2e2e2c;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .richtext {
          width: 100%;
          margin-top: 8px;
          font-size: 14px;
          color: #4099ff;
          cursor: pointer;
          &:hover {
            cursor: pointer;
          }
        }

        /deep/.el-date-editor.el-input,
        /deep/.el-date-editor.el-input__inner,
        /deep/.el-select {
          width: 100%;
        }
      }
    }

    > .buts {
      width: 100%;
      display: flex;
      margin-top: 8px;
    }
  }

  .but {
    width: auto;
    height: 30px;
    background-color: #415fff;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 8px;

    > i {
      margin-right: 5px;
    }
  }
  /deep/ .el-upload-list {
    height: 28px;
  }
  /deep/.el-upload-list__item {
    transition: none;
  }
  /deep/ .el-list-enter-active,
  /deep/ .el-list-leave-active {
    transition: none;
  }

  /deep/ .el-list-enter,
  /deep/ .el-list-leave-active {
    opacity: 0;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes transformAnimation {
  0% {
    transform: translateX(700px);
  }

  100% {
    transform: translateX(0px);
  }
}
</style>
