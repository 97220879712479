<!--
 * @Author: yang
 * @Date: 2024-08-12 17:14:28
 * @LastEditors: 
 * @LastEditTime: 2024-09-09 11:01:38
 * @Description: 文件注释:
-->
<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
import store from "@/store";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
  created() {
    // 在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem("store")) {
      store.replaceState(
        Object.assign(
          {},
          store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }

    // 页面刷新时，将vuex里的信息保存到localStorage
    // 在页面刷新时先触发beforeunload事件
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(store.state));
    });
   
  }
}
</script>
<style></style>
