<template>
  <div id="ueditor_dialog" @click="cancelDialog">
    <!-- 题干及答案等的编辑器弹出框 -->
    <div
      class="content"
      :style="{ width: ueditor.width }"
      @click.stop="clickNull"
    >
      <div class="title">{{ ueditor.title }}</div>
      <div class="colse" @click="cancelDialog">
        <i class="el-icon-close"></i>
      </div>
      <div class="con">
        <div class="cc">
          <vue-ueditor-wrap
            v-model="ueditor.content"
            editor-id="editor"
            :config="editorConfig"
            :editorDependencies="['ueditor.config.js', 'ueditor.all.js']"
            @ready="editReady"
            style="height: 540px"
          />
          <!-- <vue-ueditor-wrap
            v-model="ueditor.content"
            editor-id="editor"
            :config="editorConfig"
            :editorDependencies="['ueditor.config.js', 'ueditor.all.js']"
            style="height: 540px"
          /> -->
        </div>
      </div>
      <div class="buts">
        <div class="but" @click="makeSure()">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import Ueditor from "@/components/Ueditor";
export default {
  components: {
    Ueditor,
  },
  name: "ueditor_dialog",
  props: {
    // width: {
    //   type: String,
    //   default: "40%",
    // },
    // title: {
    //   type: String,
    //   default: "",
    // },
    // content: {
    //   // 编辑器内容
    //   type: String,
    //   default: "",
    // },
    ueditorData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      isActive: this.active,
      editorConfig: {
        serverUrl: process.env.VUE_APP_BASE_API + "/ueditor/config",
        //前端资源
        // UEDITOR_HOME_URL: "/static/ueditor/",

        // UEDITOR_CORS_URL: "/static/ueditor/",
        UEDITOR_HOME_URL: "/static/UEditorPlus/",
        UEDITOR_CORS_URL: "/static/UEditorPlus/",
        zIndex: 99999, //设置z轴顺序值，避免工具栏下拉组件被遮挡,
        initialFrameHeight: 400,
        toolbars: [
      [
        "fullscreen", // 全屏
        "source", // 源代码
        "|",
        "undo", // 撤销
        "redo", // 重做
        "|",
        "bold", // 加粗
        "italic", // 斜体
        "underline", // 下划线
        "fontborder", // 字符边框
        "strikethrough", // 删除线
        "superscript", // 上标
        "subscript", // 下标
        "removeformat", // 清除格式
        "formatmatch", // 格式刷
        "autotypeset", // 自动排版
        "blockquote", // 引用
        "pasteplain", // 纯文本粘贴模式
        "|",
        "forecolor", // 字体颜色
        "backcolor", // 背景色
        "insertorderedlist", // 有序列表
        "insertunorderedlist", // 无序列表
        "selectall", // 全选
        "cleardoc", // 清空文档
        "|",
        "rowspacingtop", // 段前距
        "rowspacingbottom", // 段后距
        "lineheight", // 行间距
        "|",
        // "customstyle",         // 自定义标题
        "paragraph", // 段落格式
        "fontfamily", // 字体
        "fontsize", // 字号
        "|",
        "directionalityltr", // 从左向右输入
        "directionalityrtl", // 从右向左输入
        "indent", // 首行缩进
        "|",
        "justifyleft", // 居左对齐
        "justifycenter", // 居中对齐
        "justifyright",
        "justifyjustify", // 两端对齐
        "|",
        "touppercase", // 字母大写
        "tolowercase", // 字母小写
        "|",
        // "link",                // 超链接
        // "unlink",              // 取消链接
        // "anchor",              // 锚点
        // "|",
        // "imagenone",           // 图片默认
        // "imageleft",           // 图片左浮动
        // "imagecenter",         // 图片居中
        // "imageright",          // 图片右浮动
        // "|",
        "simpleupload", // 单图上传
        // "insertimage",         // 多图上传
        // "emotion",             // 表情
        // "scrawl",              // 涂鸦
        "insertvideo", // 视频
        // "insertaudio",         // 音频
        // "attachment",          // 附件
        // "insertframe",         // 插入Iframe
        // "insertcode",          // 插入代码
        // "pagebreak",           // 分页
        // "template",            // 模板
        // "background",          // 背景
        // "formula",             // 公式
        // "|",
        // "horizontal",          // 分隔线
        // "date",                // 日期
        // "time",                // 时间
        // "spechars",            // 特殊字符
        // "wordimage",           // Word图片转存
        // "|",
        // "inserttable",         // 插入表格
        // "deletetable",         // 删除表格
        // "insertparagraphbeforetable",     // 表格前插入行
        // "insertrow",           // 前插入行
        // "deleterow",           // 删除行
        // "insertcol",           // 前插入列
        // "deletecol",           // 删除列
        // "mergecells",          // 合并多个单元格
        // "mergeright",          // 右合并单元格
        // "mergedown",           // 下合并单元格
        // "splittocells",        // 完全拆分单元格
        // "splittorows",         // 拆分成行
        // "splittocols",         // 拆分成列
        // "|",
        // "print",               // 打印
        "preview", // 预览
        "searchreplace", // 查询替换
        // "|",
        // "contentimport",
        // "help",                // 帮助
      ],
    ],
      },
      content: "<p>欢迎使用</p>",
      ueditor: this.ueditorData,
      editor: null,
    };
  },
  watch: {
    active() {
      this.isActive = this.active;
    },
    isActive(newValue) {
      this.$emit("showActive", this.isActive);
    },
  },
  methods: {
    initData() {},
    editReady(ue) {
      this.editor = ue;
    },

    cancelDialog() {
      this.$emit("cancelDialog");
    },
    clickNull() {},
    makeSure() {
      let content = this.ueditorData.content;
      let text = this.editor.getContentTxt();
      this.$emit("makeSure", { contentHtml: content, text: text });

      // this.$emit("makeSure", { contentHtml: content, text: text });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="less" scoped>
#ueditor_dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #00000088;
  z-index: 99;

  > .content {
    width: 35%;
    height: auto;
    // max-height: 80vh;
    background-color: #fff;
    min-height: 100px;
    margin-top: 10%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.116),
      0 0 6px rgba(212, 212, 212, 0.294);
    padding: 0 10px;

    > .title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      font-weight: bolder;
    }

    > .colse {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    > .con {
      width: 100%;
      height: 550px;
      overflow-y: auto;
      // padding: 10px;
      box-sizing: border-box;

      > .cc {
        width: 98%;
      }
    }

    > .buts {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      > .but {
        width: auto;
        height: auto;
        padding: 5px 20px;
        background-color: #38a5ee;
        color: #fff;
        box-sizing: border-box;
        font-size: 15px;
        cursor: pointer;
        border-radius: 10px;
      }
    }
  }

  /deep/ .gapfilling-span {
    /* background-color: antiquewhite; */
    display: inline-block;
    /* text-decoration: underline; */
    border-bottom: 1px solid #444;
    padding: 0 30px;
    margin: 0 5px;
  }
}
</style>
